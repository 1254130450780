import Layout from 'app/layout/Layout'
import React, { Fragment } from 'react'
import { Autocomplete, Box, Button, Chip, Grid, IconButton, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import TextEditor from 'app/components/TextEditor/TextEditor';
import { InputField } from 'app/components/UI/TextField';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveButton from 'app/components/Buttons/SaveButton';
import colors from 'app/styles/colors';
import ComposeButton from 'app/components/Buttons/ComposeButton';
import NotificationServices from 'app/apis/Notification/NotificationServices';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import UserServices from 'app/apis/User/UserServices';
import { Error } from 'app/components/UI/Error';

function PushNotification() {

  //* For Get Users ID's
  const [usersList, setUsersList] = useState([])
  const [passengerList, setPassengerList] = useState([])
  const [driverList, setDriverList] = useState([])

  //* Handle Users For Push Notification
  const [role, setRole] = useState('')
  const handleRole = (event) => {
    setRole(event.target.value);
  };

  //* For Handle Multiple selected Emails
  const [passengerId, setPassengerId] = useState([]);
  const [driverId, setDriverId] = useState([]);

  const handlePassengerChange = (event) => {
    const {
      target: { value },
    } = event;
    setPassengerId(
      // On autofill we get a stringified value.
      // typeof value === 'string' ? value.split(',') : value,
      value
    );
  };
  const handleDriverChange = (event) => {
    const {
      target: { value },
    } = event;
    setDriverId(
      // On autofill we get a stringified value.
      // typeof value === 'string' ? value.split(',') : value,
      value
    );
  };
  //*  For get Users List
  const getUsersList = async () => {
    try {
      const { data } = await UserServices.getUsersList();
      if (data === null) return
      setPassengerList(data.passengers)
      setDriverList(data.drivers)
      // setUsersList([...data.passengers, ...data.drivers])
    } catch (error) {
      ErrorToaster(error)
    }
  }
  useEffect(() => {
    getUsersList()
  }, [])
  // *For Sending Notification
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();

  const sendNotification = async (formData) => {
    // setLoader(true)
    try {
      let obj = {
        subject: formData.title,
        message: formData.message,
        users: [...formData.driverId, ...formData.passengerId],
      }

      const { data } = await NotificationServices.sendNotification(obj);
      if (data === null) return
      SuccessToaster("Notification Send Successfully")
      reset()

    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      // setLoader(false)
      setTimeout(() => (window.location.reload(false)), 500)
    }
  }

  return (
    <Fragment>
      <Typography component="form" onSubmit={handleSubmit(sendNotification)} sx={{ mt: 6 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant='h5' fontWeight='bold' py={1}>Push Notification</Typography>
          <ComposeButton type="submit" title={'Send'} icon={<SendIcon />} />
          {/* <SaveButton title={'Send'} icon={<SendIcon />} /> */}
        </Box>
        <Box bgcolor={colors.white} p={2}>
          <Grid container >
            <Grid item xs={3} px={2} alignSelf="center">
              <Typography sx={{ my: 2 }}>Title :</Typography>
            </Grid>
            <Grid item xs={6} px={2}>
              <InputField
                label="Title"
                type="title"
                error={errors?.title?.message}
                register={register("title", {
                  required: "Please fill title field.",
                  pattern: {
                    message: "Please fill title field.",
                  },
                })} />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={3} px={2}>
              <Typography sx={{ my: 2 }}>Message :</Typography>
            </Grid>
            <Grid item xs={6} px={2}>
              <InputField
                label="Message"
                type="message"
                multiline
                rows={5}
                error={errors?.message?.message}
                register={register("message", {
                  required: "Please fill message field.",
                  pattern: {
                    message: "Please fill message field.",
                  },
                })} />
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid item xs={3} px={2} alignSelf="center">
              <Typography sx={{ my: 2 }}>Select User :</Typography>
            </Grid>
            <Grid item xs={6} px={2}>
              <FormControl fullWidth sx={{ my: 1.5, }} >
                <InputLabel >Role</InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  value={role}
                  label="Role"
                  onChange={handleRole}
                  inputProps={register("role", {
                    required: "Select user's Role",
                    pattern: {
                      message: "User Role is required",
                    },
                  })}>
                  <MenuItem value={"passenger"}>Passenger</MenuItem>
                  <MenuItem value={"driver"}>Driver</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid container >
            <Grid item xs={3} px={2} alignSelf="center">
              <Typography sx={{ my: 2 }}> Passenger Id :</Typography>
            </Grid>
            <Grid item xs={6} px={2}>
              <FormControl fullWidth sx={{ my: 1.5, borderRadius: "12px" }}>
                <InputLabel>Passenger ID</InputLabel>
                <Select
                  multiple
                  value={passengerId}
                  onChange={handlePassengerChange}
                  input={<OutlinedInput label="Passenger ID" />}
                  renderValue={(selected) => selected.join(', ')}
                  inputProps={register("passengerId", {
                    required: {
                      value: !driverId.length && !passengerId.length,
                      message: "Please Select User id.",
                    },
                    // pattern: {
                    //   message: "Please enter user id.",
                    // },
                  })}
                  sx={{ borderRadius: "8px" }}
                >

                  {passengerList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      <Checkbox checked={passengerId.indexOf(item.id) > -1} />
                      <ListItemText primary={`${item.id}-${item.name}`} />
                    </MenuItem>
                  ))}
                </Select>
                <Error message={errors?.passengerId?.message} />
              </FormControl>
            </Grid>
            <Grid container>
              <Grid item xs={3} px={2} alignSelf="center">
                <Typography sx={{ my: 2 }}> Driver Id :</Typography>
              </Grid>
              <Grid item xs={6} px={2}>
                <FormControl fullWidth sx={{ my: 1.5, borderRadius: "12px" }}>
                  <InputLabel>Driver ID</InputLabel>
                  <Select
                    multiple
                    value={driverId}
                    onChange={handleDriverChange}
                    input={<OutlinedInput label="Driver ID" />}
                    renderValue={(selected) => selected.join(', ')}
                    inputProps={register("driverId", {
                      required: {
                        value: !driverId.length && !passengerId.length,
                        message: "Please Select User id.",
                      },
                      // pattern: {
                      //   message: "Please enter user id.",
                      // },
                    })}
                    sx={{ borderRadius: "8px" }}
                  >
                    {driverList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        <Checkbox checked={driverId.indexOf(item.id) > -1} />
                        <ListItemText primary={`${item.id}-${item.name}`} />
                      </MenuItem>
                    ))}
                  </Select>
                  <Error message={errors?.driverId?.message} />
                </FormControl>
              </Grid>
            </Grid>
            {/* <Autocomplete
                fullWidth sx={{
                  my: 1.5,
                  borderRadius: "12px"
                }}
                size="small"
                multiple
                id="id-tags"
                options={usersList}
                getOptionLabel={(option) => option?.id.toString()}
                filterSelectedOptions
                onChange={(event, value) => { setValue("userId", value); console.log("target here =====>", value) }}
                renderInput={(params) => (
                  <TextField
                    register={register("userId")}
                    error={errors?.userId?.message}
                    {...params}
                    label="User Id"
                    placeholder="001"
                  />
                )}/> */}
            {/* <InputField
                label="Email"
                type="email"
                error={errors?.email?.message}
                register={register("email", {
                  required: "Please enter email address.",
                  pattern: {
                    message: "Please enter email address.",
                  },
                }
                )} /> */}
          </Grid>
          {/* <Grid container >
          <Grid item xs={3} px={2} alignSelf="center">
            <Typography sx={{ my: 2 }}>Date :</Typography>
          </Grid>
          <Grid item xs={6} px={2}>
            <InputField />
          </Grid>
        </Grid>
        <Grid container >
          <Grid item xs={3} px={2}>
            <Typography sx={{ my: 2 }}>Audience :</Typography>
          </Grid>
          <Grid item xs={2} px={2}>
            <Box display="flex" my={1}>
              <Chip label="Passengers" variant="outlined" sx={{
                width: "200px",
                borderRadius: "8px",
              }} />
              <IconButton>
                <AddIcon sx={{ color: colors.ufoGreen }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item xs={3} px={2}>
            <Typography sx={{ my: 1 }}>Attachment :</Typography>
          </Grid>
          <Grid item xs={2} px={2}>
            <IconButton>
              <AttachFileIcon sx={{ fontSize: "16px" }} />
              <Typography>Image 01</Typography>
            </IconButton>
            <IconButton>
              <AddIcon sx={{ color: colors.ufoGreen }} />
            </IconButton>
          </Grid>
        </Grid> */}
        </Box>
      </Typography>
    </Fragment>
  )
}

export default PushNotification