import React, { Fragment, useState } from 'react'
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, TextField, } from '@mui/material';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from 'app/components/Buttons/Button';
import colors from 'app/styles/colors';
import { Error } from 'app/components/UI/Error';
import UserManagementServices from 'app/apis/UserManagement/UserManagementServices';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { useNavigate } from 'react-router-dom';

function AddUser() {
  const navigate = useNavigate()
  const [role, setRole] = useState('')
  const handleRole = (event) => {
    setRole(event.target.value);
  };
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const addNewUser = async (formData) => {
    try {
      const obj = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone: formData.phone,
        email: formData.email,
        gender: formData.gender,
        password: formData.password,
        role_id: formData.role === "manager" ? 5 : "",
      }

      const { data } = await UserManagementServices.createEmployees(obj);
      if (data === null) return
      console.log("🚀 ~ file: AddUser.js:29 ~ addNewUser ~ data:", data)
      SuccessToaster("User Add Successfully")
      reset()
      navigate('/view-users')
    }
    catch (error) {
      ErrorToaster(error)
    }
  }
  const [gender, setGender] = useState("")

  const handleSelect = (event) => {
    setGender(event.target.value)
  }

  return (
    <Fragment>
      <Box >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant='h5' fontWeight='bold' py={1}>Create User</Typography>
        </Box>

        <Box bgcolor={colors.white} borderRadius="8px" py={3}>

          <Typography component="form" onSubmit={handleSubmit(addNewUser)} sx={{ mt: 2 }}>
            <Box display="flex" justifyContent="space-between" >
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="First Name"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.firstName?.message && true}
                helperText={errors?.firstName?.message}
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "Enter User's First Name",
                  },
                  // pattern: {message: "Enter User's First Name" },
                })}
              />
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="Last Name"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.lastName?.message && true}
                helperText={errors?.lastName?.message}
                {...register("lastName", {
                  required: {
                    value: true,
                    message: "Enter User's Last Name",
                  },
                  // pattern: { message: "Enter User's Last Name",},
                }
                )}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" >
              <FormControl fullWidth sx={{ my: 1.5, mx: 1, }}>
                <InputLabel >Gender</InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  defaultValue={gender}
                  label="Gender"
                  onChange={handleSelect}
                  error={errors?.gender?.message && true}
                  inputProps={register("gender", {
                    required: {
                      value: true,
                      message: "Please select user's gender.",
                    },
                  })}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
                {errors && <Error message={errors.gender?.message} />}
              </FormControl>
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="Phone"
                type="tel"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.phone?.message && true}
                helperText={errors?.phone?.message}
                {...register("phone", {
                  required: {
                    value: true,
                    message: "Enter user's phone No",
                  }
                }
                )} />
            </Box>
            <Box display="flex" justifyContent="space-between" >
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="Email"
                type="email"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.email?.message && true}
                helperText={errors?.email?.message}
                {...register("email", {
                  required: {
                    value: true,
                    message: "Enter user's email",
                  },
                  pattern: { message: "Enter valid email", },
                }
                )} />
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="Password"
                type="password"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.password?.message && true}
                helperText={errors?.password?.message}
                {...register("password", {
                  required: {
                    value: true,
                    message: "Enter user's password",
                  }
                }
                )}
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <FormControl sx={{ my: 1.5, mx: 1, width: "48%" }}>
                <InputLabel >Role</InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  defaultValue={role}
                  label="Role"
                  onChange={handleRole}
                  error={errors?.role?.message && true}
                  inputProps={register("role", {
                    required: {
                      value: !role,
                      message: "Please select user's role.",
                    },
                    pattern: {
                      message: "Please select user role.",
                    },
                  })}
                >
                  {/* <MenuItem value={"admin"}>Admin</MenuItem> */}
                  <MenuItem value={"manager"}>Manager</MenuItem>
                </Select>
                {errors && <Error message={errors.role?.message} />}
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="center" sx={{ mb: 2, width: "100%" }}>
              <Box sx={{ mb: 2, width: "300px" }}>
                <PrimaryButton title={"Create User"} type="submit" />
              </Box>
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  )
}

export default AddUser