import { get, post, patch, deleted } from '../index';
import TripRoutes from "./Trip.Routes";
const TripServices = {
  getAllRides: async (page) => {
    // const page = 1
    const data = await get(TripRoutes.getAllRides + `?page=${page}` + `&limit=${10}`)
    return data
  },
  getRideDetails: async (id) => {
    const data = await get(TripRoutes.getRideDetails + `?ride_id=${id}`)
    return data
  }
}

export default TripServices