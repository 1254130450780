import { ChatOutlined, Assessment, AssessmentTwoTone, GridViewOutlined, People, MailOutlineOutlined, NotificationsOutlined, PeopleAlt, QuestionAnswerRounded, ReportRounded, SettingsOutlined, TimeToLeaveRounded, TaxiAlert, Campaign } from "@mui/icons-material"
import colors from 'app/styles/colors';

const styles = {
  iconSize: {
    p: 0.2,
    fontSize: "20px",
    color: colors.lightSecondary,
  }
}

const Navigation = [
  {
    label: 'Dashboard',
    icon: <GridViewOutlined sx={styles.iconSize} />,
    path: '/',
    access: ['super', 'manager'],
  },
  // {
  //   label: 'Users',
  //   icon: <PeopleAlt sx={styles.iconSize} />,
  //   childPath: ['/passengers', '/drivers'], // for side nave sub menu active
  //   children: [
  //     {
  //       label: 'Passengers',
  //       path: '/passengers'
  //     },
  //     {
  //       label: 'Drivers',
  //       path: '/drivers'
  //     },
  //   ]
  // },
  // {
  //   label: 'Add User',
  //   icon: <PeopleAlt sx={styles.iconSize} />,
  //   path: '/add-user'
  // },

  {
    label: 'Passengers Report',
    icon: <PeopleAlt sx={styles.iconSize} />,
    path: '/passengers',
    access: ['super', 'manager'],
  },
  {
    label: 'Drivers Report',
    icon: <People sx={styles.iconSize} />,
    path: '/drivers',
    access: ['super', 'manager'],
  },
  {
    label: 'Trips/Rides Report',
    icon: <TimeToLeaveRounded sx={styles.iconSize} />,
    path: '/trip-detail',
    access: ['super', 'manager'],
  },
  {
    label: 'Vehicles Report',
    icon: <TaxiAlert sx={styles.iconSize} />,
    path: '/vehicles',
    access: ['super', 'manager'],
  },
  {
    label: 'Earning Report',
    icon: <AssessmentTwoTone sx={styles.iconSize} />,
    path: '/earn-report',
    access: ['super', 'manager'],
  },
  {
    label: 'Commission Report',
    icon: <Assessment sx={styles.iconSize} />,
    path: '/commission-report',
    access: ['super', 'manager'],
  },
  {
    label: 'Manage Users',
    icon: <PeopleAlt sx={styles.iconSize} />,
    path: '/view-users',
    access: ['super']
  },
  {
    label: 'Fare Algorithm',
    icon: <SettingsOutlined sx={styles.iconSize} />,
    path: '/fare-algorithm',
    access: ['super', 'manager'],
  },
  // {
  //   label: 'Reports',
  //   icon: <ReportRounded sx={styles.iconSize} />,
  //   childPath: ['/earn-report', '/commission-report'], // for side nave sub menu active 
  //   children: [
  //     {
  //       label: 'Earning Report',
  //       path: '/earn-report',
  //     },
  //     {
  //       label: 'Commission Report',
  //       path: '/commission-report',
  //     },
  //   ]
  // },
  // {
  //   label: 'Promotions',
  //   icon: <Campaign sx={styles.iconSize} />,
  //   path: '/promotion-list',
  // },
  {
    label: 'Mail Integration',
    icon: <MailOutlineOutlined sx={styles.iconSize} />,
    path: '/mail-compose',
    access: ['super', 'manager'],
  },
  // {
  //   label: 'SMS Integration',
  //   icon: <ChatOutlined sx={styles.iconSize} />,
  //   path: '/sms-integration',
  // },
  {
    label: 'Push Notification',
    icon: <NotificationsOutlined sx={styles.iconSize} />,
    path: '/push-notification',
    access: ['super', 'manager'],
  },

  // {
  //   label: 'System Configuration',
  //   icon: <SettingsOutlined sx={styles.iconSize} />,
  //   childPath: ['/fare-algorithm', '/commission'], // for side nave sub menu active 
  //   children: [
  //     {
  //       label: 'Fare Algorithm',
  //       path: '/fare-algorithm'
  //     },
  //     {
  //       label: 'Commission',
  //       path: '/commission'
  //     },
  //   ]
  // },

  // {
  //   label: 'Reports',
  //   icon: <ReportRounded sx={styles.iconSize} />,
  //   path: '/reports',
  // },
  {
    label: 'Chat Support',
    icon: <QuestionAnswerRounded sx={styles.iconSize} />,
    path: '/chat-supports',
    access: ['super', 'manager'],
  },
]

export default Navigation