import { React, useState } from 'react'
//*Mui imports
import { Box, Typography, } from '@mui/material';
import { makeStyles } from "@mui/styles"
//*Components Import
import colors from 'app/styles/colors'
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Error } from 'app/components/UI/Error';
import AuthLayout from './AuthLayout';
import Images from 'app/assets/Images';
import { PrimaryButton } from 'app/components/Buttons/Button';
import { InputField } from 'app/components/UI/TextField';
import { ErrorToaster } from 'app/components/Toaster/Toaster';

//* Form Styling
const useStyles = makeStyles({
  loginBox: {
    backgroundColor: colors.white,
    borderRadius: "8px",
    boxShadow: `0px 20px 24px ${colors.shadow}`,
  },
  link: {
    textDecoration: "none",
    color: colors.secondary,
    marginLeft: "6px",
  },
  signUp: {
    textDecoration: "none",
    color: colors.primary,
    marginLeft: "6px",
  },
})

function ForgetPassword() {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, formState: { errors } } = useForm();

  //* For Submit Recover password form
  const recoverPassword = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        email: formData.email,
      }
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  return (
    <AuthLayout>

      <Box className={classes.loginBox}
        sx={{ mt: 2, mx: 1, py: 4, px: 3 }}>
        <Box sx={{ textAlignLast: "center" }}>
          <img src={Images.logo} alt="logoImage" style={{ width: "100px" }} />
        </Box>
        <Typography variant='h5' sx={{ textAlign: "center", my: 4, fontWeight: 'bold' }}>
          Recover Your Password
        </Typography>
        <Typography variant='body2' sx={{ textAlign: "center", my: 4 }}>
          Enter Your Email And We'll Send You Instructions To Reset Your Password
        </Typography>
        <Typography component="form"
          onSubmit={handleSubmit(recoverPassword)}
          sx={{ mt: 6 }}>
          <InputField
            label="Email"
            type="email"
            error={errors?.email?.message}
            register={register("email", {
              required: "Please enter your name.",
              pattern: {
                message: "Please enter your name for registration.",
              },
            }
            )}
          />
          <PrimaryButton type="submit"
            loading={loading}
            title={'Send Reset Password'} />
          <Box sx={{ my: 1.5 }}>
            <Typography
              variant="body1"
              color={colors.secondary}
              sx={{ textAlign: "center" }}>
              Go Back To
              <Link to="/login" className={classes.signUp}>
                Login
              </Link>
            </Typography>
          </Box>
        </Typography>
      </Box>
    </AuthLayout>
  )
}

export default ForgetPassword