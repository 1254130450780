import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import colors from 'app/styles/colors';
import Layout from 'app/layout/Layout';
import MapIntegrate from 'app/components/Map/MapIntegrate';
import DialogPassengerDetail from 'app/components/DialogBox/DialogPassengerDetail';
import DialogTripDetail from 'app/components/DialogBox/DialogTripDetail';
import { sosTripData } from 'app/utils/dummyData';
//* MUI Imports
import { Box, Typography, Avatar, Card, CardContent } from '@mui/material';
import TripServices from 'app/apis/Trip/TripServices';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import { useParams } from 'react-router-dom';
import { getTime } from 'app/utils/Dateformate';
import { Loading } from 'app/components/UI/Loader';
import socketService from 'app/utils/socketService';
import { CleaningServices } from '@mui/icons-material';

//* Location points For Direction
// const markers = [
//   {
//     lat: 24.904870,
//     lng: 67.074088
//   },
//   {
//     lat: 24.918816,
//     lng: 67.097220
//   }];

function TripsReport() {
  const [loader, setLoader] = useState(true)
  const [rideDetails, setRideDetails] = useState([])
  const [markers, setMarkers] = useState([])
  const [length, setLength] = useState()
  const { tripId } = useParams()
  const [rideTime, setRideTime] = useState({ startTime: '', endTime: '' })
  const [coords, setCoords] = useState([])


  const getRideDetails = async (id) => {
    setLoader(true)
    try {
      const { data } = await TripServices.getRideDetails(id)
      if (data === null) return
      setLength(data.ide?.ride_destinations?.length - 1)

      setRideDetails(data.ride)
      setMarkers([
        {
          lat: +data.ride?.latitude,
          lng: +data.ride?.longitude
        },
        {
          lat: +data.ride.ride_destinations[data.ride?.ride_destinations?.length - 1]?.latitude,
          lng: +data.ride.ride_destinations[data.ride?.ride_destinations?.length - 1]?.longitude
        }
      ])
      data.ride.ride_logs.map((item) => {
        if (item.status_id === 6) {
          return setRideTime((currentState) => { return { ...currentState, startTime: getTime(item.created_at) } })
        } else if (item.status_id === 11) {
          return setRideTime((currentState) => { return { ...currentState, endTime: getTime(item.created_at) } })
        }
      })
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  useLayoutEffect(() => {
    socketService.initializeSocket();
  }, [])

  useEffect(() => {
    getRideDetails(tripId);

    console.log('socket')
    setTimeout(() => {
      socketService.emit("join-room", {
        room: tripId,
      })
    }, 2000);

    setTimeout(() => (
      socketService.on('get-location', data => {
        console.log('data', data)
        setCoords([{
          lat: data?.position?.latitude,
          lng: data?.position?.longitude
        }])

      })
    ), 5000)

    return () => {
      socketService.disconnect()
    }

  }, []);

  return (
    <Fragment>
      <Typography variant='h5' fontWeight='bold' py={2}>TRIP DETAIL</Typography>
      {loader ? (
        <Loading />
      ) : (
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Card sx={{ width: "450px", borderRadius: "18px", boxShadow: `0px 1px 2px 2px ${colors.shadow} ` }}>
              <CardContent>
                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: 1,
                  borderBottomColor: colors.silver,
                  py: 1
                }} >
                  <DialogTripDetail title={sosTripData.title.id} text={rideDetails.id} />
                  <DialogTripDetail title={sosTripData.title.time} text={getTime(rideDetails.createdAt)} />
                  {rideDetails.ride_earning ?
                    <DialogTripDetail title={sosTripData.title.name} text={rideDetails?.ride_earning?.actual_fare} />
                    :
                    <DialogTripDetail title={sosTripData.title.name} text={rideDetails?.total_amount} />
                  }

                  {rideDetails.vehicle ?
                    <DialogTripDetail title={sosTripData.title.number} text={rideDetails.vehicle.veh_number} />
                    :
                    <DialogTripDetail title={sosTripData.title.number} text={"N/A"} />
                  }

                </Box>
                {/* Ride Detail */}
                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: 1,
                  borderBottomColor: colors.silver,
                  py: 1
                }} >
                  <Box px={2} pt={3}>
                    <Typography sx={{ fontSize: "9px" }}>Distance</Typography>
                    <Typography fontSize='18px' fontWeight='bold'>
                      {rideDetails.est_distance}&nbsp;
                      <Typography component='span'>
                        KM
                      </Typography>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "9px" }}>Pickup</Typography>
                    <Typography sx={{ fontSize: "11px", fontWeight: "bold", pb: 2 }}>{rideDetails.address}</Typography>
                    <Typography sx={{ fontSize: "9px" }}>Drop Off</Typography>
                    <Typography sx={{ fontSize: "11px", fontWeight: "bold" }}>{rideDetails?.ride_destinations[rideDetails?.ride_destinations?.length - 1]?.address}</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "10px", fontWeight: "bold", p: 2 }}>{rideTime?.startTime}</Typography>
                    <Typography sx={{ fontSize: "10px", fontWeight: "bold", p: 2 }}>{rideTime?.endTime}</Typography>

                  </Box>
                </Box>
                {/* Passenger Detail */}
                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: 1,
                  borderBottomColor: colors.silver,
                  py: 2
                }} >
                  <Box>
                    <Typography sx={{ fontSize: "10px", mb: 1 }} >Passenger</Typography>
                    <Avatar sx={{ mr: 2 }} />
                  </Box>
                  <DialogPassengerDetail title={"Name"} text={rideDetails?.passenger?.name} />
                  <DialogPassengerDetail title={"Email"} text={rideDetails?.passenger?.email} />
                  {/* <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} /> */}
                </Box>

                {/* Ride Status */}
                <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: 0,
                  borderBottomColor: colors.silver,
                  pt: 2
                }} >
                  <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: "12px", mb: 1 }} >Ride Status</Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: `${rideDetails.ride_status.color}` }} >{rideDetails.ride_status.status}</Typography>
                  </Box>
                </Box>
                {/* Driver Detail */}
                {/* <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: 0,
                  borderBottomColor: colors.silver,
                  pt: 2
                }} >
                  <Box>
                    <Typography sx={{ fontSize: "10px", mb: 1 }} >Driver Details</Typography>
                    <Avatar sx={{ mr: 2 }} />
                  </Box>
                  <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} />
                  <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} />
                  <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} />
                </Box> */}
              </CardContent>
            </Card>
            {rideDetails.ride_earning ?
              <Card sx={{ width: "450px", borderRadius: "18px", my: 2, boxShadow: `0px 1px 2px 2px ${colors.shadow}` }}>
                <CardContent sx={{ display: "flex", justifyContent: "space-around", textAlign: "center", pt: 3 }}>

                  {rideDetails.ride_earning.fare &&
                    <Box>
                      <Typography sx={{ fontSize: "9px" }}>Fare</Typography>
                      <Typography variant='body1' fontWeight='bold'>${rideDetails?.ride_earning?.actual_fare}</Typography>
                    </Box>
                  }
                  {rideDetails.ride_earning.commission &&
                    <Box>
                      <Typography sx={{ fontSize: "9px" }}>Total Commission</Typography>
                      <Typography variant='body1' fontWeight='bold'>${rideDetails?.ride_earning?.rido_earning}</Typography>
                    </Box>
                  }
                  {rideDetails.ride_earning &&
                    <Box>
                      <Typography sx={{ fontSize: "9px" }}>Driver Earning</Typography>
                      <Typography variant='body1' fontWeight='bold'>${rideDetails?.ride_earning?.driver_earning}</Typography>
                    </Box>
                  }
                  {/* {rideDetails.tip &&
                      <Box>
                        <Typography sx={{ fontSize: "9px" }}>Tip</Typography>
                        <Typography variant='body1' fontWeight='bold'>${rideDetails.tip}</Typography>
                      </Box>
                    } */}
                  {/* {rideDetails.gst &&
                      <Box>
                        <Typography sx={{ fontSize: "9px" }}>Gst</Typography>
                        <Typography variant='body1' fontWeight='bold'>${rideDetails.gst}</Typography>
                      </Box>
                    } */}
                </CardContent>
              </Card>
              :
              <Fragment>

              </Fragment>
            }
          </Box>
          {/* Map Integration Component */}
          <Box sx={{ width: "49%" }}>
            <MapIntegrate markers={markers} rideStatus={rideDetails?.ride_status} coords={coords} />
          </Box>
        </Box>
      )}
    </Fragment>
  )
}

export default TripsReport