import { useState } from "react";

function useProvideAuth() {

  const [user, setUser] = useState(localStorage.getItem('token'));
  const [userName, setUserName] = useState(localStorage.getItem('name'))
  const [role, setRole] = useState(localStorage.getItem('role'));

  // *User
  const userLogin = (data) => {
    localStorage.setItem('token', data.token)
    localStorage.setItem('name', data.name)
    localStorage.setItem('email', data.email)
    localStorage.setItem('role', data.role)
    setUser(data.token)
    setRole(data.role)
    setUserName(data.name)
  };

  const userLogout = async () => {
    setUser(null)
    setRole(null)
    localStorage.clear()
  };

  // *Verify Token
  const verifyToken = () => {
    const token = localStorage.getItem('token')
    const name = localStorage.getItem('name')
    const role = localStorage.getItem('role')
    setUser(token)
    setUserName(name)
    setRole(role)
  };

  return {
    userName,
    user,
    role,
    userLogin,
    userLogout,
    verifyToken
  };
}

export default useProvideAuth;