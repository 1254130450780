import React from 'react';

//* Components Import
import colors from 'app/styles/colors';
import { paymentRows } from 'app/utils/dummyData';
import { getDate } from 'app/utils/Dateformate';

//* MUI Imports
import { Box, Typography, Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeStyles } from '@mui/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

//* Data Table Styling
const StyledTableHead = styled(TableHead)(() => ({
  border: 0,
  '& .MuiTableCell-head': {
    border: 0,
  },
}));

const StyledTableBody = styled(TableBody)(() => ({
  border: 0,
  '& .MuiTableCell-body': {
    border: 0,
    fontWeight: 700,
    fontSize: '12px', minHeight: '16px',
    lineHeight: '16px',
    color: `${colors.secondary}`,
    boxSizing: 'none', borderBottom: 'none',
  },
}));


function PaymentReportTable() {

  return (
    <Card sx={{ width: "49.5%", my: 1, borderRadius: "10px", boxShadow: `0px 1px 2px 2px ${colors.shadow}`, }}>
      <CardContent sx={{ width: '95%', }}>
        <Box display="flex" justifyContent="space-between" sx={{ pb: 1.5 }}>
          <Typography variant='h6' fontWeight="bold">Payment Report</Typography>
          <MoreHorizIcon />
        </Box>
        <Box>
          <TableContainer>
            <Table size="small">
              <StyledTableHead >
              </StyledTableHead>
              <StyledTableBody >
                {paymentRows.map((row, index) => {
                  if (index < 5) {
                    return (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Box display="flex" alignItems="center">
                            <Avatar src={row.img} alt="RemySharp"
                              sx={{ width: 32, height: 32, mr: 1 }} />
                            {row.username}
                          </Box>
                        </TableCell>
                        <TableCell >{getDate(row.updatedAt)}</TableCell>
                        <TableCell >
                          <Typography fontFamily="Roboto" color={colors.ufoGreen}>
                            <ArrowUpwardIcon style={{ color: colors.ufoGreen, fontSize: '16px' }} />
                            {`$${row.amount}`}
                          </Typography>
                          <Typography fontSize="10px">Approved</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }
                })}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>
    </Card>
  )
}

export default PaymentReportTable