
import { get, post, patch, deleted } from '../index';
import FareControlRoutes from './FareControl.Routes';

const FareControlServices = {
  getFareCalculation: async () => {
    const data = await get(FareControlRoutes.getFareCalculation);
    return data;
  },
  updateFareCalculation: async (obj) => {
    const data = await patch(FareControlRoutes.updateFareCalculation, obj);
    return data;
  },
}

export default FareControlServices;