import React, { Fragment, useEffect, useState } from 'react'
import Layout from 'app/layout/Layout';
import UserTable from 'app/components/DataTable/UserTable';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { Typography, TableRow, TableCell, Box, Avatar, IconButton, Button, Chip } from '@mui/material';
import { Loading } from 'app/components/UI/Loader';
import { baseUrl } from '../../../../axios'
import { Link, useNavigate } from 'react-router-dom';
import Edit from '@mui/icons-material/Edit';
import Person2 from '@mui/icons-material/Person2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import colors from 'app/styles/colors';
import UserManagementServices from 'app/apis/UserManagement/UserManagementServices';
import Swal from 'sweetalert2';
import ConfirmationDialog from 'app/components/DialogBox/ConfirmationDialog';

//* Table Header Array
const tableHeader = ["First Name", "Last Name", "Role", "Email", "Phone No", "Gender", "Password", "Update", "Delete"];
function ViewUsers() {
  const navigate = useNavigate();
  const [allEmployees, setAllEmployees] = useState([])
  const [loader, setLoader] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [userId, setUserId] = useState('')

  //* For Pagination
  const per_Page = 10;
  const [count, setCount] = useState(1)
  const [page, setPage] = useState(1)
  const onPageChange = (pageNum) => {
    setPage(() => pageNum)
    getAllEmployees(pageNum)
  }

  //*  API Get Employees Data 
  const getAllEmployees = async () => {
    setLoader(true)
    let obj = {
      page: page ? page : 1
    }
    try {
      const { data } = await UserManagementServices.getAllEmployees(obj.page);
      if (data === null) return
      setAllEmployees(data.employees)
      setCount(Math.ceil(data.count / per_Page))
    } catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  };

  const deleteUser = async () => {

    setLoader(true)
    try {
      const { status, responseCode, message } = await UserManagementServices.deleteEmployees(userId)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        getAllEmployees()
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }

  }
  const resetPassword = async (id) => {
    // setLoader(true)
    try {
      const { status, responseCode, message } = await UserManagementServices.resetPassword(id)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        // getAllEmployees()
      }
    } catch (error) {
      ErrorToaster(error)
    }
    // } finally {
    //   setLoader(false)
    // }
  }

  useEffect(() => {
    getAllEmployees()
  }, []);

  return (
    <Fragment>
      <ConfirmationDialog open={confirmDialog} onClose={() => setConfirmDialog(false)} action={deleteUser} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant='h5' fontWeight='bold' py={1}>USERS</Typography>
        <Button variant='contained'
          onClick={() => { navigate('/add-user') }}
          startIcon={<Person2 />}
          sx={{
            mx: 1,
            width: '130px',
            height: '32px',
            fontSize: 12,
            borderRadius: "10px",
            textTransform: "capitalize",
            backgroundColor: colors.ufoGreen,
            color: colors.white,
            boxShadow: 'none',
            "&:hover": {
              backgroundColor: colors.ufoGreen,
            }
          }}> Create User </Button>
      </Box>
      <Box >
        {loader ? (
          <Loading />
        ) : (
          <UserTable tableHeader={tableHeader} count={count} onPageChange={onPageChange} page={page}>
            {allEmployees && allEmployees?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Box display="flex" alignItems="center">
                    <Avatar src={baseUrl + row?.picture} alt="RemySharp"
                      sx={{ width: 32, height: 32, mr: 1 }} />
                    {row.first_name}
                  </Box>
                </TableCell>
                <TableCell >{row.last_name}</TableCell>
                <TableCell >{"Manager"}</TableCell>
                <TableCell >{row.email}</TableCell>
                <TableCell >{row.phone}</TableCell>
                <TableCell >{row.gender}</TableCell>
                <TableCell >
                  <Chip
                    onClick={() => { resetPassword(row.id) }}
                    size='small' label="Reset & Resend" color="primary" variant='outlined' sx={{
                      fontSize: "12px", cursor: "pointer", ":hover": {
                        bgcolor: colors.primary, color: colors.secondary
                      }
                    }} />
                </TableCell>
                <TableCell >
                  <IconButton component={Link} to={`/update-user`} state={{ data: row }}>
                    <Edit sx={{ color: colors.lightSecondary }} />
                  </IconButton>
                </TableCell>
                <TableCell >
                  {/* () => deleteUser(row.id) */}
                  <IconButton onClick={() => { setUserId(row.id); setConfirmDialog(true) }}>
                    <DeleteForeverIcon sx={{ color: colors.lightSecondary }} />
                  </IconButton>
                </TableCell>
                <TableCell />
              </TableRow>
            ))}

          </UserTable>
        )}
      </Box>
    </Fragment >
  )
}

export default ViewUsers