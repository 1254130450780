import { Box, Toolbar } from '@mui/material'
import React, { Fragment } from 'react'
import Header from './Header'
import SideNav from './SideNav'
import colors from '../styles/colors'
import { Outlet } from 'react-router-dom'
const drawerWidth = 240;

function Layout({ children }) {
  return (
    <Fragment>
      <Header />
      <SideNav />
      <Box display="flex" sx={{ backgroundColor: colors.background, minHeight: "100vh" }}>
        <Box component="main"
          sx={{ flexGrow: 1, p: 2.5, ml: 30, my: 1.5, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <Toolbar />
          {/* {children} */}
          <Outlet />
        </Box>
      </Box>
    </Fragment>
  )
}

export default Layout