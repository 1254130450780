import React from 'react'
import { Box, Typography } from '@mui/material';

function DialogTripDetail(props) {
  return (
    <Box>
      <Typography sx={{ fontSize: "9px", px: 1 }}>{props.title}</Typography>
      <Typography variant='body1' fontWeight='bold' sx={{ color: `${props.color}`, px: 1 }}>{props.text}</Typography>
    </Box>
  )
}

export default DialogTripDetail