import { useState } from 'react';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

//* Component Imports
import colors from 'app/styles/colors'
import AuthLayout from './AuthLayout';
import { PrimaryButton } from 'app/components/Buttons/Button';
import { InputField } from 'app/components/UI/TextField';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import useAuth from 'app/hooks/useAuth';
import AuthServices from 'app/apis/Auth/AuthServices';

//* MUI Imports
import { Box, Typography, Checkbox, FormGroup, FormControlLabel, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles } from "@mui/styles"
import { getUserToken } from 'app/firebase/firebase';

const useStyles = makeStyles({
  loginBox: {
    backgroundColor: colors.white,
    borderRadius: "8px",
    boxShadow: `0px 20px 24px ${colors.shadow}`,
  },
  link: {
    textDecoration: "none",
    color: colors.secondary,
    marginLeft: "6px",
  },
  signUp: {
    textDecoration: "none",
    color: colors.primary,
    marginLeft: "6px",
  }
})

function Login() {

  const classes = useStyles();
  const navigate = useNavigate();
  const { userLogin } = useAuth();
  const [loading, setLoading] = useState(false);

  // *For Show Password Toggle
  const [showPassword, setShowPassword] = useState(false);

  // *For Submit Login Form
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onLogin = async (formData) => {
    setLoading(true);
    try {
      let fcmToken = await getUserToken()
      let obj = {
        email: formData.email,
        password: formData.password,
        fcm_token: fcmToken
      }
      const { status, responseCode, data, message } = await AuthServices.login(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        localStorage.setItem('token', data.token)
        localStorage.setItem('fcmToken', fcmToken)
        userLogin(data)
        navigate('/')
      }

    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout>
      <Box className={classes.loginBox}
        sx={{ mt: 2, mx: 1, py: 4, px: 4 }}>
        <Typography variant='h6' sx={{ textAlign: "center", my: 4, fontWeight: 'bold' }}>
          Login to your account
        </Typography>
        <Typography component="form" onSubmit={handleSubmit(onLogin)} sx={{ mt: 6 }}>

          <InputField
            label="Login"
            type="email"
            error={errors?.email?.message}
            register={register("email", {
              required: "Please enter your name.",
              pattern: {
                message: "Please enter your name for registration.",
              },
            }
            )}
          />

          <InputField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            error={errors?.password?.message}
            register={register("password", {
              required: "Please enter password.",
              minLength: {
                value: 6,
                message: "Password must have at least 6 characters.",
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormGroup >
            <FormControlLabel
              control={<Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />}
              label={<Typography variant="body1" color={colors.secondary}>
                Remember Me
              </Typography>}
            />
          </FormGroup>
          <PrimaryButton
            title={'Login'}
            type="submit"
            loading={loading}
          />

          <Box>
            <Typography
              variant="body2"
              color={colors.secondary}
              sx={{ textAlign: "center", my: 1 }}
            >
              <Link to="/forgot-password" className={classes.link}>
                Forgot Your Password?
              </Link>
            </Typography>
          </Box>
          <Box sx={{ my: 4 }}>
            <Typography
              variant="body1"
              color={colors.secondary}
              sx={{ textAlign: "center" }}
            >
              Don't have an account?
              <Link to="/register" className={classes.signUp}>
                SignUp
              </Link>
            </Typography>
          </Box>
        </Typography>
      </Box>
    </AuthLayout>
  )
}

export default Login