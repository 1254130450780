import io from 'socket.io-client';

const SOCKET_URL = 'https://rido.mangotech-api.com';

class WSService {

  initializeSocket = async () => {
    try {

      this.socket = io(SOCKET_URL)
      console.log("🚀 ~ file: socketService.js:11 ~ WSService ~ initializeSocket= ~ socket", this.socket)

      this.socket.on('connect', (data) => {
        console.log("=== socket connected ====")
      })

      this.socket.on('disconnect', (data) => {
        console.log("=== socket disconnected ====")
      })

      this.socket.on('error', (data) => {
        console.log("socket error", data)
      })

    } catch (error) {
      console.log("socket is not initialized", error)
    }
  }

  emit(event, data = {}) {
    this.socket.emit(event, data)
  }

  off(eventName) {
    this.socket.off(eventName)
  }

  on(event, cb) {
    console.log("🚀 ~ file: socketService.js:38 ~ WSService ~ on ~ event", event)
    this.socket.on(event, cb)
  }

  open() {
    this.socket.open()
  }

  removeListener(listenerName) {
    this.socket.removeListener(listenerName)
  }

  disconnect() {
    this.socket.disconnect()
  }
}

const socketService = new WSService()

export default socketService