import React, { Fragment, useEffect, useState } from 'react'
//* Components Import
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { baseUrl } from '../../../axios'
// *MUI Imports
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, Pagination, Grid, Avatar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { userRows } from 'app/utils/dummyData';
import { StickyNote2Outlined, Visibility, FullscreenExit } from '@mui/icons-material';
import TableSort from 'app/components/Sort/TableSort';
import SimpleCard from './../../components/Cards/SimpleCard';
import { DataSaverOff, AutoAwesomeMosaic } from '@mui/icons-material';
import StatsServices from 'app/apis/Stats/StatsServices';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import { Link } from 'react-router-dom';
import { Loading } from 'app/components/UI/Loader';
import UserServices from 'app/apis/User/UserServices';
import TripReceipt from 'app/components/DialogBox/TripReceipt';
//* Data Table Styling
const StyledTableHead = styled(TableHead)(() => ({
  border: 0,
  '& .MuiTableCell-head': {
    minHeight: '16px',
    padding: '14px'
  },
}));

const StyledTableBody = styled(TableBody)(() => ({

  '& .MuiTableCell-body': {
    fontWeight: 600,
    fontSize: '12px', minHeight: '16px',
    lineHeight: '16px',
    color: `${colors.secondary}`,
    boxSizing: 'none',
  },
}));
const tableHeader = ["Ride ID", "DriverName", "PassengerName", "Actual Fare", "Driver Earning", "Receipt", "Trip Detail"];

function EarningReport() {
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState('')
  const [receiptData, setReceiptData] = useState('')
  const [loader, setLoader] = useState(false);
  const [earnReport, setEarnReport] = useState("")
  //* For Pagination
  let [page, setPage] = useState(1);
  const [count, setCount] = useState(1)
  const handleChange = (e, value) => {
    setPage(value);
    getReports(value, "", "", "");
  };
  //*For Getting Sort Dates
  const getDates = (toDate, fromDate, driverId) => {
    if (toDate !== undefined && fromDate !== undefined) {
      getReports(1, toDate, fromDate, driverId ? driverId : "")
      setPage(1)
    }
    else if (driverId !== undefined) {
      getReports(1, "", "", driverId)
      setPage(1)
    }
    else return
  }

  //* For Reports API Call
  const getReports = async (page, toDate, fromDate, driverId) => {

    try {
      setLoader(true)
      let type = "earnings"
      let obj = {
        page: page ? page : 1,
        dateTo: toDate ? toDate + " 23:59:59" : "",
        dateFrom: fromDate ? fromDate + " 00:00:00" : "",
        driver_id: driverId ? driverId : ""
      }

      const { data } = await StatsServices.getReports(obj.page, type, obj.dateTo, obj.dateFrom, obj.driver_id)
      if (data === null) return
      setEarnReport(data)
      setCount(Math.ceil(data.count / 8))
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }
  const getReceipt = async (TripId) => {
    try {
      const { data } = await StatsServices.getReceipt(TripId);
      if (data === null) return
      setReceiptData(data)
    } catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }
  //* For Driver ID's for Sort API Call
  const [driverList, setDriverList] = useState([])

  const getUsersList = async () => {
    try {
      const { data } = await UserServices.getUsersList();
      if (data === null) return
      setDriverList(data.drivers)
    } catch (error) {
      ErrorToaster(error)
    }
  }
  useEffect(() => {
    getReports()
    getUsersList()
  }, [])
  return (
    <Fragment>
      <TripReceipt open={open} setOpen={() => { setOpen(!open); setReceiptData("") }} data={receiptData} />
      <Typography variant='h5' sx={{ fontWeight: 'bold', pt: 1 }} >EARNING REPORT</Typography>
      <Box display="flex" justifyContent="space-around" alignItems="center" py={1.5}>
        <Box> <TableSort getDates={getDates} driverList={driverList} /></Box>
        <Box sx={{ width: "35%" }}>
          <SimpleCard total={earnReport?.total ? `$${earnReport?.total}` : "0"} name={"Total Earning"}
            icon={<DataSaverOff sx={{
              backgroundColor: colors.active, color: colors.notification, fontSize: '24px', borderRadius: "10px", padding: "8px"
            }} />} />
        </Box>
      </Box>
      {/* <TableSort /> */}

      {/* <Notification /> */}

      <Box>
        {loader ? (
          <Box display="flex" justifyContent="center" pl={15}>
            <Loading />
          </Box>
        ) :
          (
            <TableContainer>
              <Table stickyHeader sx={{ backgroundColor: colors.white }}>
                <StyledTableHead>
                  <TableRow>
                    {tableHeader.map((item, index) => (
                      <TableCell key={index}>{item}</TableCell>
                    ))}
                  </TableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {earnReport?.ride_earnings?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.ride_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box display="flex" alignItems="center">
                          <Avatar src={baseUrl + row?.vehicle?.driver?.picture} alt="RemySharp"
                            sx={{ width: 32, height: 32, mr: 1 }} />
                          {row?.vehicle?.driver?.name}
                        </Box>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box display="flex" alignItems="center">
                          <Avatar src={baseUrl + row?.passenger?.picture} alt="RemySharp"
                            sx={{ width: 32, height: 32, mr: 1 }} />
                          {row?.passenger?.name}
                        </Box>
                      </TableCell>
                      <TableCell >{row?.ride_earning?.actual_fare}</TableCell>
                      <TableCell >{row?.ride_earning?.driver_earning}</TableCell>
                      <TableCell >
                        <IconButton onClick={() => { setOpen(!open); getReceipt(row?.ride_id) }}>
                          <StickyNote2Outlined sx={{ color: colors.lightSecondary }} />
                        </IconButton>
                      </TableCell>
                      <TableCell >
                        {/* <IconButton>
                      <RoomIcon style={{ color: colors.opal }} />
                    </IconButton> */}
                        <IconButton component={Link} to={`/trip-report/${row.ride_id}`}>
                          <FullscreenExit sx={{ color: colors.lightSecondary }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                  )}
                </StyledTableBody>
                <TableFooter>
                  <TableRow>

                  </TableRow>
                </TableFooter>
              </Table>
              <Box sx={{ py: 3 }}>
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  shape="rounded"
                  color="primary"
                  sx={{ '.MuiPagination-ul': { justifyContent: 'center' } }}
                />
              </Box>
            </TableContainer>
          )}
      </Box>
    </Fragment>
  )
}

export default EarningReport