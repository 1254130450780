import { React, useRef, useState } from 'react'
import colors from 'app/styles/colors'
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import AuthLayout from './AuthLayout';
import { PrimaryButton } from 'app/components/Buttons/Button';
import { InputField } from 'app/components/UI/TextField';
import Images from 'app/assets/Images';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
//* MUI Components
import { Box, Typography, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles"
import Layout from 'app/layout/Layout';

const useStyles = makeStyles({

  loginBox: {
    backgroundColor: colors.white,
    borderRadius: "8px",
    boxShadow: `0px 20px 24px ${colors.shadow}`,
  },
  link: {
    textDecoration: "none",
    color: colors.secondary,
    marginLeft: "6px",
  },
  signUp: {
    textDecoration: "none",
    color: colors.primary,
    marginLeft: "6px",
  }
})


function ResetPassword() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  // *For Show Password Toggle
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  //
  const password = useRef({});
  password.current = watch("newPassword", "");

  // *For Submit Recover password form
  const resetPassword = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      }
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  return (
    <Layout>
      <Box className={classes.loginBox} sx={{ mt: 2, py: 4, px: 3 }}>
        <Box sx={{ textAlignLast: "center" }}>
          <img src={Images.logo} alt="LogoImage" style={{ width: "100px" }} />
        </Box>
        <Typography variant='h6' sx={{ textAlign: "center", mt: 6, fontWeight: 'bold' }}>
          Reset Your Password
        </Typography>
        <Box display="flex" justifyContent="center" sx={{ px: 24 }}>
          <Typography component="form"
            onSubmit={handleSubmit(resetPassword)}
            sx={{ mt: 2 }}>

            <InputField
              label="Current Password"
              type={showPassword ? 'text' : 'password'}
              error={errors?.password?.message}
              register={register("currentPassword", {
                required: "Please enter password.",
                minLength: {
                  value: 6,
                  message: "Password must have at least 6 characters.",
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <InputField
              label="New Password"
              type={showPassword ? 'text' : 'password'}
              error={errors?.password?.message}
              register={register("newPassword", {
                required: "Please enter new password.",
                minLength: {
                  value: 6,
                  message: "Password must have at least 6 characters.",
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <InputField
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              error={errors?.confirmPassword?.message}
              register={register("confirmPassword", {
                required: "Please re-type your password.",
                minLength: {
                  value: 6,
                  message: "Password must have at least 6 characters.",
                },
                validate: value => value === password.current || "Confirm Password does not match",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end">
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ mb: 2 }}>
              <PrimaryButton title={"Reset Password"} type="submit" loading={loading} />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Layout>
  )
}

export default ResetPassword