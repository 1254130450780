import React, { Fragment, useEffect, useState } from 'react'
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import Layout from 'app/layout/Layout';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from 'app/components/Buttons/Button';
import colors from 'app/styles/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import UserManagementServices from 'app/apis/UserManagement/UserManagementServices';
import { async } from '@firebase/util';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';


function UpdateUser() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [role, setRole] = useState('')
  const handleRole = (event) => {
    setRole(event.target.value);
  };
  const [gender, setGender] = useState("")

  const handleSelect = (event) => {
    setGender(event.target.value)
  }
  const { setValue, register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const updateUser = async (formData) => {

    try {
      const obj = {
        name: formData.firstName + " " + formData.lastName,
        phone: formData.phone,
        email: formData.email,
        id: formData.id,
      }
      const { data } = await UserManagementServices.updateEmployees(obj);
      if (data === null) return
      SuccessToaster("User Updated Successfully")
      reset()
      navigate('/view-users')
    }
    catch (error) {
      ErrorToaster(error)
    }

  }
  const defaultData = () => {

    setValue('firstName', state?.data.first_name)
    setValue('lastName', state?.data.last_name)
    setValue('phone', state?.data.phone)
    setValue('email', state?.data.email)
    setValue('id', state?.data.id)
  }

  useEffect(() => {
    defaultData()
  })

  return (
    <Fragment>
      <Box >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant='h5' fontWeight='bold' py={1}>Update User</Typography>
        </Box>

        <Box bgcolor={colors.white} borderRadius="8px" py={3}>

          <Typography component="form" onSubmit={handleSubmit(updateUser)} sx={{ mt: 2 }}>
            <Box display="flex" justifyContent="space-between" >
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="First Name"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.firstName?.message}
                helperText={errors?.firstName?.message}
                {...register("firstName", {
                  required: "Enter User's First Name",
                  pattern: {
                    message: "Enter User's First Name",
                  },
                })}
              />
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="Last Name"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.lastName?.message}
                helperText={errors?.lastName?.message}
                {...register("lastName", {
                  required: "Enter User's Last Name",
                  pattern: {
                    message: "Enter User's Last Name",
                  },
                }
                )}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" >
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="Phone"
                type="tel"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.phone?.message}
                helperText={errors?.phone?.message}
                {...register("phone", {
                  required: "Enter user's phone No",
                  pattern: {
                    message: "Phone Number required",
                  },
                }
                )} />
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="Email"
                type="email"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.email?.message}
                helperText={errors?.email?.message}
                {...register("email", {
                  required: "Enter user's email",
                  pattern: {
                    message: "Email is required",
                  },
                }
                )} />
            </Box>


            <Box display="flex" justifyContent="center" sx={{ mb: 2, width: "100%" }}>
              <Box sx={{ mb: 2, width: "300px" }}>
                <PrimaryButton title={"Update User"} type="submit" />
              </Box>
            </Box>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  )
}

export default UpdateUser