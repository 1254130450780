import React from 'react'
import { Button } from '@mui/material'
import colors from 'app/styles/colors';

function CardButton(props) {
  return (
    <Button
      onClick={props.setOpen}
      // disabled={props.disabled}
      // {...props}
      variant='contained'
      sx={{
        width: '80px',
        height: '24px',
        fontSize: 12,
        fontWeight: 'bold',
        borderRadius: "14px",
        textTransform: "capitalize",
        // backgroundColor: colors.active,
        // color: colors.ufoGreen,
        boxShadow: 'none',
        bgcolor: props.bgcolor,
        color: props.color,
        "&:hover": props.hover,
      }}>
      {props.title}
    </Button>
  )
}

export default CardButton