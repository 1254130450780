import React, { useState } from 'react'
//* MUI Imports
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Pagination } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import colors from 'app/styles/colors';

//* Data Table Header Styling
const StyledTableHead = styled(TableHead)(() => ({
  border: 0,
  '& .MuiTableCell-head': {
    minHeight: '16px',
    padding: '14px'
  },
}));
//* Data Table Body Styling
const StyledTableBody = styled(TableBody)(() => ({

  '& .MuiTableCell-body': {
    fontWeight: 700,
    fontSize: '11px', minHeight: '16px',
    lineHeight: '16px',
    color: `${colors.secondary}`,
    boxSizing: 'none',
  },
}));

//* Logic for Pagination
export function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data?.slice(begin, end);
  }

  // function next() {
  //   setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  // }

  // function prev() {
  //   setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  // }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }
  // next, prev,
  return { jump, currentData, currentPage, maxPage };
}

//* Table 
function DetailsTable({ children, tableHeader, count, onPageChange, page }) {
  //* For Pagination
  // let [page, setPage] = useState(1);
  const handleChange = (e, pageNum) => {
    onPageChange(pageNum)
    // setPage(p);
    // _DATA.jump(p);
  };

  return (
    <TableContainer>
      <Table stickyHeader sx={{ backgroundColor: colors.white }}>
        <StyledTableHead>
          <TableRow>
            {tableHeader.map((item, index) => (
              <TableCell key={index}>{item}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </StyledTableHead>
        <StyledTableBody>
          {children}
        </StyledTableBody>
      </Table>
      {/* for Pagination */}
      <Box sx={{ py: 3 }}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          shape="rounded"
          color="primary"
          sx={{ '.MuiPagination-ul': { justifyContent: 'center' } }}
        />
      </Box>
    </TableContainer>
  )
}

export default DetailsTable