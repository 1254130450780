import React, { useState, useEffect, Fragment } from 'react';
//* Components Import
import Layout from 'app/layout/Layout';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import { Loading } from 'app/components/UI/Loader';
import UserTable, { usePagination } from 'app/components/DataTable/UserTable';
import UserServices from 'app/apis/User/UserServices';
import { getDate } from 'app/utils/Dateformate';
import { baseUrl } from '../../../../axios'
import colors from 'app/styles/colors';
//* MUI Imports
import { Avatar, Box, IconButton, TableCell, TableRow, Typography, } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import PreviewIcon from '@mui/icons-material/Preview';
import { Link } from 'react-router-dom';
import TableSort from 'app/components/Sort/TableSort';
import PassengerDialog from './../../../components/DialogBox/PassengerDialog';

//* Table Header Array
const tableHeader = ["ID", "Name", "Email", "Phone No", "Gender", "Status", "Online Status"];

function Passengers() {
  const [allPassengers, setAllPassengers] = useState([]);
  const [userRides, setUserRides] = useState([])
  const [modalData, setModalData] = useState('')
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false);

  //* For Pagination
  const per_Page = 10;
  // const length = allPassengers.length;
  const count = Math.ceil(allPassengers.length / per_Page)
  const _DATA = usePagination(allPassengers, per_Page);

  //*  API Get Passengers Data 
  const getAllPassengers = async () => {

    try {
      const { data } = await UserServices.getAllPassengers();
      if (data === null) return
      setAllPassengers(data.result)
    } catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  };

  const getUserRides = async (passengerId) => {
    try {
      const { data } = await UserServices.getUserRides(passengerId);
      if (data === null) return
      setUserRides(data.rides)
    } catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    setLoader(true)
    getAllPassengers()
  }, []);

  return (
    <Fragment>
      <Typography variant='h5' sx={{ fontWeight: 'bold', pt: 1 }}>PASSENGERS REPORT</Typography>
      <PassengerDialog open={open} setOpen={() => { setOpen(!open); setModalData("") }} data={userRides} title={"Passenger Rides"} passenger={modalData} />
      {/* <TableSort /> */}
      <Box>
        {loader ? (
          <Box display="flex" justifyContent="center" pl={15}>
            <Loading />
          </Box>
        ) : (

          <UserTable tableHeader={tableHeader} count={count} _DATA={_DATA}>
            {_DATA && _DATA.currentData()?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.id}</TableCell>
                <TableCell component="th" scope="row">
                  <Box display="flex" alignItems="center">
                    <Avatar src={baseUrl + row?.picture} alt="RemySharp"
                      sx={{ width: 32, height: 32, mr: 1 }} />
                    {row.name}
                  </Box>
                </TableCell>
                <TableCell >{row.email}</TableCell>
                <TableCell >{row.phone}</TableCell>
                <TableCell >{row.gender}</TableCell>

                <TableCell >
                  {row.is_active === true ?
                    <Typography bgcolor={colors.active} color={colors.ufoGreen} sx={{
                      borderRadius: "8px",
                      width: "80px",
                      fontSize: "14px",
                      textAlign: "center",
                    }}>Active</Typography> :
                    <Typography
                      bgcolor={colors.offline} color={colors.opal} sx={{
                        borderRadius: "8px",
                        width: "80px",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >Offline</Typography>
                  }
                </TableCell>


                <TableCell >
                  {row.is_online === true ?
                    <Typography bgcolor={colors.active} color={colors.ufoGreen} sx={{
                      borderRadius: "8px",
                      width: "80px",
                      fontSize: "14px",
                      textAlign: "center",
                    }}>Online</Typography> :
                    <Typography
                      bgcolor={colors.offline} color={colors.opal} sx={{
                        borderRadius: "8px",
                        width: "80px",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >Offline</Typography>
                  }
                </TableCell>

                <TableCell >
                  {/* <IconButton component={Link} to={"/trip-report"} > */}
                  <IconButton onClick={() => { setOpen(!open); getUserRides(row.id); setModalData(row) }} >
                    <PreviewIcon style={{ color: colors.gray }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </UserTable>
        )}
      </Box>
    </Fragment>
  )
}

export default Passengers

