
import Drivers from './../views/Users/Driver/Drivers';
import TripDetails from './../views/Trips/TripDetails/TripDetails';
import SOSAlerts from './../views/Trips/SOSAlerts/SOSAlerts';
import MailIntegration from './../views/MailIntegration/MailIntegration';
import SmsIntegration from './../views/SMSIntegration/SmsIntegration';
import PushIntegration from './../views/PushIntegration/PushIntegration';
// import Reports from './../views/Reports/Reports';
import ChatSupports from './../views/ChatSupport/ChatSupports';
import Home from './../views/Home/Home';
import Passengers from './../views/Users/Passenger/Passengers';
import VehiclesDetail from './../views/Vehicles/VehiclesDetail';
import DriversDetail from './../views/Users/Driver/DriversDetail';
import { PageNotFound } from 'app/components/NotFound/NotFound';
import { Navigate } from 'react-router-dom';
import TripsReport from './../views/Trips/TripsReport/TripsReport';
import FareAlgorithm from 'app/views/SystemConfig/FareAlgorithm';
import Commission from './../views/SystemConfig/Commission';
import TextEditor from './../components/TextEditor/TextEditor';
import MailCompose from 'app/views/MailIntegration/MailCompose';
import ResetPassword from 'app/views/Auth/ResetPassword';
import PushNotification from 'app/views/PushIntegration/PushNotification';
import AddUser from 'app/views/Users/ManageUsers/AddUser';
import ViewUsers from 'app/views/Users/ManageUsers/ViewUsers';
import UpdateUser from 'app/views/Users/ManageUsers/UpdateUser';
import PromotionsList from 'app/views/Promotions/PromotionsList';
import CreatePromo from 'app/views/Promotions/CreatePromo';
import EarningReport from 'app/views/Reports/EarningReport';
import CommissionReport from 'app/views/Reports/CommissionReport';

const PrivateRoutes = [
  {
    path: "/",
    component: <Home />
  },
  {
    path: "/update-user",
    component: <UpdateUser />
  },
  {
    path: "/add-user",
    component: <AddUser />
  },
  {
    path: "/view-users",
    component: <ViewUsers />
  },
  {
    path: "/passengers",
    component: <Passengers />
  },
  {
    path: "/drivers",
    component: <Drivers />
  },
  {
    path: "/driver-detail/:driverId",
    component: <DriversDetail />
  },
  {
    path: "/trip-detail",
    component: <TripDetails />
  },
  {
    path: "/trip-report/:tripId",
    component: <TripsReport />
  },
  {
    path: "/vehicles",
    component: <VehiclesDetail />
  },
  {
    path: "/sos-alerts",
    component: <SOSAlerts />
  },
  {
    path: "/mail-integration",
    component: <MailIntegration />
  },
  {
    path: "/mail-compose",
    component: <MailCompose />
  },
  {
    path: "/sms-integration",
    component: <SmsIntegration />
  },
  {
    path: "/push-notification",
    component: <PushNotification />
  },
  {
    path: "/fare-algorithm",
    component: <FareAlgorithm />
  },
  {
    path: "/commission",
    component: <Commission />
  },
  // {
  //   path: "//create-promo",
  //   component: <Commission />
  // },
  {
    path: "/promotion-list",
    component: <PromotionsList />
  },
  {
    path: '/create-promo',
    component: <CreatePromo />
  },
  {
    path: "/earn-report",
    component: <EarningReport />
  },
  {
    path: "/commission-report",
    component: <CommissionReport />
  },
  {
    path: "/chat-supports",
    component: <ChatSupports />
  },
  {
    path: '/reset-password',
    component: <ResetPassword />
  },
  {
    path: "*",
    component: <Navigate to="/404" />
  },
  {
    path: "/404",
    component: <PageNotFound />,
  },
];

export default PrivateRoutes;