import { Fragment, React, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
//* MUI Imports
import Badge from '@mui/material/Badge';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, Typography, IconButton, Tooltip, Avatar, Menu, MenuItem, ListItemIcon } from '@mui/material'
//* Components Import
import colors from '../styles/colors'
import Images from 'app/assets/Images';
import SOSDialog from 'app/components/DialogBox/SOSDialog';
import useAuth from './../hooks/useAuth';
import { getUserToken, onMessageListener } from 'app/firebase/firebase';
import { useEffect } from 'react';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import NotificationServices from 'app/apis/Notification/NotificationServices';
import AuthServices from 'app/apis/Auth/AuthServices';
import { getTime, relativeTime } from 'app/utils/Dateformate';

//* badge style For Notification
const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: 18,
    top: 6,
    border: `1px solid ${colors.white}`,
  },
}));
//* badge style For Avatar
const StyledAvtBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: colors.ufoGreen,
    right: -1,
    top: 28,
    border: `4px solid ${colors.white}`,
    padding: '0 4px',
  },
}));

function Header() {
  const { userLogout, userName, role } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNotify, setAnchorElNotify] = useState(null);
  const [notificationCount, setNotificationCount] = useState([])
  const [notificationData, setNotificationData] = useState([])
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  //* For SOS Notification
  const [notification, setNotification] = useState({ title: '', body: '', image: '', name: '' });
  useEffect(() => {
    if (notification?.title) {
      console.log("🚀 ~ file: Header.js:48 ~ useEffect ~ title", notification?.title)
      setOpen(!open)
      getNotificationCount()
    }
  }, [notification])



  onMessageListener()
    .then((payload) => {
      console.log("🚀 ~ file: Header.js:57 ~ .then ~ payload", payload)
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
        image: payload?.notification?.image,
        name: payload?.notification?.name
      });
    })
    .catch((err) => console.log('failed: ', err));



  //* Logout Function
  const logout = async () => {
    const fcmToken = localStorage.getItem('fcmToken')
    try {
      let obj = {
        fcm_token: fcmToken
      }
      const { data } = await AuthServices.logout(obj)
      if (data === null) return
      userLogout()
      navigate('/login')
    }
    catch (error) {

    }
  }

  //* For Notification Count
  const getNotificationCount = async () => {
    try {
      const { data } = await NotificationServices.getNotificationCount();
      if (data === null) return
      setNotificationCount(data)
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  // useEffect(() => {
  //   getNotificationCount()
  // }, [])

  //* For Notification Data
  const getAllNotification = async () => {
    try {
      const { data } = await NotificationServices.getAllNotification();
      if (data === null) return
      setNotificationData(data.notifications)
      getNotificationCount()
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  return (
    <Fragment>
      <AppBar position="fixed" sx={{
        backgroundColor: colors.white,
        borderRadius: "8px",
        width: 'calc(100% - 277px)',
        margin: "16px",
        boxShadow: "none",
      }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box display="flex" >
          </Box>
          <Box display="flex">

            {/* Notification Bell */}
            <Tooltip title="Open Notification" >
              <IconButton onClick={(e) => { setAnchorElNotify(e.currentTarget); getAllNotification(); }} sx={{ px: 2 }}>
                <StyledBadge badgeContent={notificationCount.count} color="error">
                  <NotificationsNoneOutlinedIcon />
                </StyledBadge>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: 5.5, }}
              id="notify-appBar"
              anchorEl={anchorElNotify}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNotify)}
              onClose={() => setAnchorElNotify(null)}
              PaperProps={{
                style: {
                  width: '350px',
                  maxHeight: '280px',
                  borderRadius: '10px',
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: colors.hexGray, borderRadius: "6px", mx: 1, mb: 0.6 }}>
                <Typography sx={{ py: 1, fontSize: "17px", fontWeight: "bold", }}>Notifications</Typography>
              </Box>
              <Box sx={{}}>
                {notificationData?.map((item, index) => (
                  <MenuItem key={index}>
                    <Box sx={{ display: "flex", alignItems: "center", py: 1, width: "100%" }}>
                      {item.type === "SOS" ? (
                        <Box sx={{ mr: 1 }}>
                          <Avatar
                            alt="Remy Sharp"
                            src={Images.sosImg}
                            sx={{ width: 36, height: 36 }} />
                        </Box>
                      ) : (
                        <Box sx={{ mr: 1 }}>
                          <Avatar
                            // alt="Remy Sharp"
                            // src={Images.sosImg}
                            sx={{ width: 36, height: 36 }} />
                        </Box>
                      )}

                      <Box flex={1}>
                        <Typography sx={{ fontWeight: "bold" }}>{item.title}</Typography>
                        <Typography>{item.content}</Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: '11px', color: colors.notification }}>{relativeTime(item.createdAt)}</Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </Box>
            </Menu>
            <SOSDialog open={open} setOpen={() => setOpen(!open)} data={notification} />

            {/* User AVATAR */}
            <Box sx={{ m: 1 }}>
              <Typography color={colors.secondary} fontWeight="bold" >{userName}</Typography>
              {role === "manager" ?
                <Typography color={colors.secondary} fontSize="10px" textAlign="right">Manager</Typography> :
                <Typography color={colors.secondary} fontSize="10px" textAlign="right">Admin</Typography>
              }
            </Box>
            <Tooltip title="User" >
              <IconButton onClick={(e) => setAnchorElUser(e.currentTarget)} sx={{ p: 0, m: 0.5 }}>
                <StyledAvtBadge badgeContent={""}>
                  <Avatar src={""} alt="RemySharp"></Avatar>
                </StyledAvtBadge>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appBar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              {/* <MenuItem
                sx={{ fontSize: "12px" }}
                onClick={() => setAnchorElUser(null)}
                component={Link} to={"/reset-password"}>
                <ListItemIcon>
                  <LockOutlinedIcon />
                </ListItemIcon>
                Reset Password
              </MenuItem> */}
              <MenuItem
                sx={{ fontSize: "12px" }}
                onClick={logout}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Fragment>
  )
}

export default Header