import React, { Fragment } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, FormControl, DialogActions, FormLabel, RadioGroup, FormControlLabel, Radio, Chip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import colors from 'app/styles/colors';
import DialogButton from '../Buttons/DialogButton';
import { baseUrl } from './../../../axios';
import { Swiper, SwiperSlide } from "swiper/react";
//* Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
//* import required modules
import { Navigation } from "swiper";

function VehicleDocument({ open, setOpen, data }) {

  const docImages = data?.veh_documents_values?.map((item) => {
    return baseUrl + item.path
  })

  // const Image = data?.veh_documents_values?.path

  return (
    <Box>
      <Dialog open={open} onClose={setOpen}
        PaperProps={{
          style: {
            borderRadius: "20px",
            minWidth: "400px",
          },
        }} >
        <Box display="flex" justifyContent="space-between" >
          <DialogTitle variant='h6' sx={{ fontWeight: "bold", mt: 1 }} >{data?.document_name}</DialogTitle>
          <IconButton onClick={setOpen} sx={{ "&:hover": { backgroundColor: colors.white } }}>
            <CancelIcon sx={{ fontSize: '28px', color: colors.opal, mr: 1 }} />
          </IconButton>
        </Box>
        <DialogContent>
          {docImages &&
            <Swiper navigation={true} modules={[Navigation]} >
              {docImages.map((item, index) => (
                <SwiperSlide key={index} style={{ display: "flex", justifyContent: "center" }}>
                  <img src={item} alt="Document" style={{ width: "430px", height: "340px", borderRadius: "10px", objectFit: "cover" }} />
                  {/* <img src={baseUrl + Image} alt="Document" style={{ width: "430px", height: "340px", borderRadius: "10px" }} /> */}
                </SwiperSlide>
              ))}
            </Swiper>
          }
          {/* Radio Buttons */}
          {/* <Box sx={{ pt: 2 }}>
            <FormControl >
              <FormLabel id="demo-row-radio-buttons-group-label">Vehicle Type</FormLabel>
              <RadioGroup row sx={{ color: "black" }}>
                <FormControlLabel value="rido-m" control={<Radio />} label="Rido M" />
                <FormControlLabel value="rido-l" control={<Radio />} label="Rido L" />
                <FormControlLabel value="rido-c" control={<Radio />} label="Rido Comfort" />
                <FormControlLabel value="rido-g" control={<Radio />} label="Rido Green" />
              </RadioGroup>
            </FormControl>
          </Box> */}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", m: 1 }}>
          <Chip label={data?.status}
            sx={{
              mx: 1,
              width: '110px',
              height: '28px',
              fontSize: 12,
              fontWeight: 'bold',
              borderRadius: "10px",
              textTransform: "capitalize",
              // backgroundColor: colors.active,
              // color: colors.ufoGreen,
              boxShadow: 'none',
              bgcolor: data?.status === 'required' ? colors.offline : data?.status === 'Pending' ? colors.yellowBg : colors.ufoGreen,
              color: data?.status === 'required' ? colors.opal : data?.status === 'Pending' ? colors.yellow : colors.active,
            }} />
          {/* {data?.status === 'Approved' ? (
            <DialogButton title={'Approved'} />
          ) : (
            <Fragment>
              <DialogButton title={'Reject'} />
              <DialogButton title={'Later'} />
            </Fragment>
          )} */}
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default VehicleDocument