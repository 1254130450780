import React from 'react'
import { Box, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles"

//*Components import
import Images from 'app/assets/Images';
import colors from 'app/styles/colors';

//* Layout styling
const useStyles = makeStyles({
  bgImage: {
    backgroundImage: `url(${Images.bgImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "100vh"
  },
  sideImage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    height: "500px",
  },
  loginBox: {
    backgroundColor: colors.white,
    borderRadius: "8px"
  },
  link: {
    textDecoration: "none",
    color: colors.secondary,
    marginLeft: "6px",
  },
  signUp: {
    textDecoration: "none",
    color: colors.primary,
    marginLeft: "6px",
  }
})

function AuthLayout({ children }) {
  const classes = useStyles();
  return (
    <Box sx={{ position: "relative" }} className={classes.bgImage}>
      <Grid container justifyContent="space-around" pt={3} alignItems="center">
        <Grid item md={4}>
          <Box >
            <img src={Images.rido} alt="RidoLogo" style={{ width: "130px" }} />
            {children}
          </Box>
        </Grid>
        <Grid item md={6} pt={5} >
          <Box sx={{ mt: 7, display: { sm: "none", md: "block" } }}>
            <img src={Images.loginLayer} alt="LoginForm" className={classes.sideImage} />
          </Box>
        </Grid>
      </Grid>
    </Box >
  )
}

export default AuthLayout