import React, { useState, useEffect, Fragment } from 'react';
//* Components Import
import UserServices from 'app/apis/User/UserServices';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import Layout from 'app/layout/Layout';
import DetailsTable, { usePagination } from 'app/components/DataTable/DetailsTable';
import { Loading } from 'app/components/UI/Loader';
import TableSort from 'app/components/Sort/TableSort';
import VehicleServices from 'app/apis/Vehicle/VehicleServices';
//* Import MUI
import { Box, Typography, TableRow, TableCell, } from '@mui/material';

//* Table Header Data Array
const tableHeader = ["Vehicle", "Vehicle No.", "Vehicle Model", "Vehicle Color", "Year", "Driver Name", "Email"]

function VehiclesDetail() {
  const [allVehicles, setAllVehicles] = useState([])
  const [loader, setLoader] = useState(false);

  //* For Pagination
  const per_Page = 10;
  // const length = allVehicles.length;
  // const count = Math.ceil(allVehicles.length / per_Page)
  const _DATA = usePagination(allVehicles, per_Page);
  //

  //* Get All Rides Detail API
  const getAllVehicles = async () => {
    setLoader(true);
    try {
      const { data } = await VehicleServices.getAllVehicles()
      if (data === null) return
      setAllVehicles(data.vehicles)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getAllVehicles();
  }, []);

  return (
    <Fragment>
      <Typography variant='h5' sx={{ fontWeight: 'bold', pt: 1 }}>VEHICLES REPORT</Typography>
      {/* <TableSort /> */}
      <Box >
        {loader ? (
          <Box display="flex" justifyContent="center" pl={15}>
            <Loading />
          </Box>
        ) : (
          <DetailsTable tableHeader={tableHeader} data={allVehicles} >
            {_DATA && _DATA?.currentData()?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.manufacture_name}
                </TableCell>
                <TableCell >{row.veh_number}</TableCell>
                <TableCell >{row.model_name}</TableCell>
                <TableCell >{row.color}</TableCell>
                <TableCell >{row.year}</TableCell>
                <TableCell >{row.user.name}</TableCell>
                <TableCell >{row.user.email}</TableCell>
                <TableCell />
              </TableRow>
            ))}
          </DetailsTable>
        )}
      </Box>
    </Fragment>
  )
}

export default VehiclesDetail