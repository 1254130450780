import { get, post, patch, deleted } from '../index';
import NotificationRoutes from './Notification.Routes';

const NotificationServices = {
  getNotificationCount: async () => {
    const data = await get(NotificationRoutes.getNotificationCount);
    return data;
  },
  getAllNotification: async () => {
    const data = await get(NotificationRoutes.getAllNotification);
    return data;
  },
  sendNotification: async (obj) => {
    const data = await post(NotificationRoutes.sendNotification, obj);
    return data
  },
  sendEmail: async (obj) => {
    const data = await post(NotificationRoutes.sendEmail, obj);
    return data
  }
}

export default NotificationServices;