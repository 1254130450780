import React from 'react'
import { Box, Typography } from '@mui/material';

function DriverDetailBox(props) {
  return (
    <Box>
      <Typography sx={{ fontSize: "10px" }}>{props.title}</Typography>
      <Typography variant='body1' fontWeight='bold'>{props.text}</Typography>
    </Box>
  )
}

export default DriverDetailBox