import React, { useState } from 'react'
import colors from 'app/styles/colors';
//* MUI Imports
import { styled } from '@mui/material/styles';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, IconButton, Pagination } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import TableSort from '../Sort/TableSort';

//* Data Table Header Styling
const StyledTableHead = styled(TableHead)(() => ({
  border: 0,
  '& .MuiTableCell-head': {
    minHeight: '16px',
    padding: '14px'
  },
}));
//* Data Table Body Styling
const StyledTableBody = styled(TableBody)(() => ({
  border: 0,
  '& .MuiTableCell-body': {
    fontWeight: 500,
    fontSize: '12px', minHeight: '16px',
    lineHeight: '16px',
    // color: `${colors.secondary}`,
    boxSizing: 'none',
  },
}));

//* Logic for Pagination
export function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data?.slice(begin, end);
  }

  // function next() {
  //   setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  // }

  // function prev() {
  //   setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  // }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }
  // next, prev,
  return { jump, currentData, currentPage, maxPage };
}

function UserTable({ children, tableHeader, count, onPageChange, page }) {
  //* For Pagination
  const handleChange = (e, pageNum) => {
    onPageChange(pageNum)
  };
  return (
    <Box >
      {/* <TableSort /> */}
      <TableContainer sx={{
        "&::-webkit-scrollbar": {
          height: 6,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: colors.scrollBg,
          borderRadius: 2
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.scrollC,
          borderRadius: 2,
        }
      }}>
        <Table stickyHeader sx={{ backgroundColor: colors.white }}>
          <StyledTableHead>
            <TableRow>
              {tableHeader.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {children}
          </StyledTableBody>
        </Table>
        {/* for Pagination */}
        <Box sx={{ py: 3 }}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            shape="rounded"
            color="primary"
            sx={{ '.MuiPagination-ul': { justifyContent: 'center' } }}
          />
        </Box>
      </TableContainer>
    </Box>
  )
}

export default UserTable