import React, { useState } from 'react';
//* Components Import
import Layout from 'app/layout/Layout';
import { integration } from 'app/utils/dummyData';
import { Loading } from 'app/components/UI/Loader';
import IntegrationTable, { usePagination } from 'app/components/DataTable/IntegrationTable';
import { getDate, getTime } from 'app/utils/Dateformate';
import ComposeButton from 'app/components/Buttons/ComposeButton';
//* MUI Imports
import { Box, Typography, TableRow, TableCell, Checkbox, TextField, InputAdornment, IconButton } from '@mui/material';
import colors from 'app/styles/colors';
import Search from '@mui/icons-material/Search';
import SaveAsOutlined from '@mui/icons-material/SaveAsOutlined';
import { Link } from 'react-router-dom';

const tableHeader = ["Title", "Description", "Date", "Time"]
function SmsIntegration() {
  const [loader, setLoader] = useState(false);
  //* For Pagination
  const per_Page = 10;
  const count = Math.ceil(integration.length / per_Page);
  const _DATA = usePagination(integration, per_Page);
  return (
    <Layout>
      <Typography variant='h5' sx={{ fontWeight: "bold", pt: 1 }}>SMS INTEGRATION</Typography>

      <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
        <Box sx={{ width: 450, borderRadius: "8px" }}>
          <TextField
            sx={{
              width: '100%', height: "34px", backgroundColor: colors.white, borderRadius: "8px",
              "& .MuiOutlinedInput-notchedOutline": { height: "34px", border: 0 },
            }}
            size='small'
            placeholder='Search'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type='submit' edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <ComposeButton title={'Compose'} icon={<SaveAsOutlined />} component={Link} to={"/mail-compose"} />
      </Box>
      <Box>
        {loader ? (
          <Loading />
        ) : (
          <IntegrationTable tableHeader={tableHeader} count={count} _DATA={_DATA}>
            {_DATA.currentData().map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Checkbox
                    color="primary"
                    size='small'
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell >
                  <Typography fontWeight="bold">{row.title}</Typography>
                  <Typography fontSize="11px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" width="500px" >{row.desc}</Typography>
                </TableCell>
                <TableCell >{getDate(row.date)}</TableCell>
                <TableCell >{getTime(row.time)}</TableCell>
              </TableRow>
            )
            )}
          </IntegrationTable>
        )}
      </Box>
    </Layout>
  )
}

export default SmsIntegration