const colors = {
  primary: "#368738",
  secondary: "#1E1E1E",
  background: "#FAFAFA",
  notification: "#44b700",
  shadow: "#8D8D8D29",
  lightSecondary: "#2f2e2e",
  white: "#ffffff",
  silver: "#C5C5C5",
  hexGray: "#FAFAFA",
  gray: "#656565",
  ufoGreen: "#28C66F",
  ufoBg: "#dcfae9",
  purple: "#6259CE",
  opal: "#ed706f",
  opalBg: "#fff1f0",
  active: "#B8eecf",
  iconBg: "#dff7e9",
  offline: "#f7cdcb",
  skyBlue: "#50ecfa",
  skyBlueBg: "#d2f9fc",
  yellow: "#f5be19",
  yellowBg: "#fae5bb",
  golden: "#f2a705",
  scrollC: "#bfbdbd",
  scrollBg: "#e0e0e0",
  danger: "#ff0000",
}

export default colors;