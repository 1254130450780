import React, { useState, useEffect, Fragment } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Layout from 'app/layout/Layout';
import FareAlgorithmBox from 'app/components/Box/FareAlgorithmBox';
import DoneIcon from '@mui/icons-material/Done';
import SaveButton from 'app/components/Buttons/SaveButton';
import colors from 'app/styles/colors';
import UserServices from 'app/apis/User/UserServices';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { Loading } from 'app/components/UI/Loader';
import { useForm } from 'react-hook-form';
import FareControlServices from 'app/apis/FareControl/FareControlServices';

function FareAlgorithm() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loader, setLoader] = useState(true);
  const [fareCalculation, setFareCalculation] = useState()
  //* API Update Documents Status
  const updateFareCalculation = async (formData) => {
    // setLoader(true)
    try {
      let obj = {
        commission: formData.commission,
        gst: formData.gst,
        service_fee: formData.serviceFee,
        per_stop_charge: formData.chargePerStop,
        per_km_charge: formData.chargePerKilometer,
        per_minute_charge: formData.chargePerMinutes,
        free_minutes: formData.freeMinutes,
        minimum_fare: formData.minimumFare,
        cancel_charges: formData.cancelCharges,
        cancel_per_km_charge: formData.cancelChargesPerKM,
        mess_in_car_charge: formData.messCarCharges,
        min_waiting_time: formData.waitingMinutes,
      }
      const { data } = await FareControlServices.updateFareCalculation(obj);
      if (data === null) return
      SuccessToaster("Fares Updated Successfully")
      getFareCalculation()
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      // setLoader(false)
    }
  }
  //* API Get Fare Calculation Data
  const getFareCalculation = async () => {
    try {
      setLoader(true)
      const { data } = await FareControlServices.getFareCalculation();
      if (data === null) return
      setFareCalculation(data.factors)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getFareCalculation()
  }, [])
  return (
    <Fragment>
      <Typography component="form" onSubmit={handleSubmit(updateFareCalculation)}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant='h5' fontWeight='bold' py={1}>FARE ALGORITHM</Typography>
          <SaveButton title={'save'} icon={<DoneIcon />} type="submit" />
        </Box>
        {loader ? (
          <Box display="flex" justifyContent="center" pl={15}>
            <Loading />
          </Box>
        ) :
          (
            <Box bgcolor={colors.white} borderRadius="8px" py={3}>
              <Box display="flex" justifyContent="space-between" >
                <FareAlgorithmBox text={"Commission"} data={fareCalculation.commission}
                  error={errors?.commission?.message} register={register("commission", {
                    // required: 'Please enter data',
                  }
                  )} />
                <FareAlgorithmBox text={"GST"} data={fareCalculation.gst}
                  error={errors?.gst?.message} register={register("gst", {
                    // required: 'Please enter data',
                  }
                  )} />
              </Box>
              {/* <Box display="flex" justifyContent="space-between" >
              <FareAlgorithmBox text={"Charge On Time"} />
              <FareAlgorithmBox text={"Charge Per Kilometer"} />
            </Box> */}
              <Box display="flex" justifyContent="space-between" >
                <FareAlgorithmBox text={"Service Fee"} data={fareCalculation.service_fee}
                  error={errors?.serviceFee?.message} register={register("serviceFee", {
                    // required: 'Please enter data',
                  }
                  )} />
                <FareAlgorithmBox text={"Charge Per Stop"} data={fareCalculation.per_stop_charge}
                  error={errors?.chargePerStop?.message} register={register("chargePerStop", {
                    // required: 'Please enter data',
                  }
                  )} />
              </Box>
              <Box display="flex" justifyContent="space-between" >
                <FareAlgorithmBox text={"Charge Per Kilometer"} data={fareCalculation.per_km_charge}
                  error={errors?.chargePerKilometer?.message} register={register("chargePerKilometer", {
                    // required: 'Please enter data',
                  }
                  )} />
                <FareAlgorithmBox text={"Charge Per Minutes"} data={fareCalculation.per_minute_charge}
                  error={errors?.chargePerMinutes?.message} register={register("chargePerMinutes", {
                    // required: 'Please enter data',
                  }
                  )} />
              </Box>
              <Box display="flex" justifyContent="space-between" >
                <FareAlgorithmBox text={"Free Minutes"} data={fareCalculation.free_minutes}
                  error={errors?.freeMinutes?.message} register={register("freeMinutes", {
                    // required: 'Please enter data',
                  }
                  )} />
                <FareAlgorithmBox text={"Minimum Fare"} data={fareCalculation.minimum_fare}
                  error={errors?.minimumFare?.message} register={register("minimumFare", {
                    // required: 'Please enter data',
                  }
                  )} />
              </Box>
              <Box display="flex" justifyContent="space-between" >
                <FareAlgorithmBox text={"Minimum Waiting Minutes"} data={fareCalculation.min_waiting_time}
                  error={errors?.waitingMinutes?.message} register={register("waitingMinutes", {
                    // required: 'Please enter data',
                  }
                  )} />
                <FareAlgorithmBox text={"Mess in Car Charges"} data={fareCalculation.mess_in_car_charge}
                  error={errors?.messCarCharges?.message} register={register("messCarCharges", {
                    // required: 'Please enter data',
                  }
                  )} />
              </Box>
              <Box display="flex" justifyContent="space-between" >
                <FareAlgorithmBox text={"Cancel Charges"} data={fareCalculation.cancel_charges}
                  error={errors?.cancelCharges?.message} register={register("cancelCharges", {
                    // required: 'Please enter data',
                  }
                  )} />
                <FareAlgorithmBox text={"Cancel Charges per KM"} data={fareCalculation.cancel_per_km_charge}
                  error={errors?.cancelChargesPerKM?.message} register={register("cancelChargesPerKM", {
                    // required: 'Please enter data',
                  }
                  )} />
              </Box>
              {/* <Box display="flex" justifyContent="space-between" >
              <FareAlgorithmBox text={"Free Minutes"} />
              <FareAlgorithmBox text={"Rido L"} />
            </Box> */}
            </Box>
          )}
      </Typography>
    </Fragment>
  )
}

export default FareAlgorithm