import { get, post, patch, deleted } from '../index';
import StatsRoutes from './Stats.Routes';

const StatsServices = {
  getStats: async () => {
    const data = await get(StatsRoutes.getStats)
    return data
  },
  getReports: async (page, type, to, from, driver_id) => {

    const data = await get(StatsRoutes.getReports + `?page=${page}` +
      `&limit=${8}` + `&type=${type}` + `&dateTo=${to}` + `&dateFrom=${from}` + `&driver_id=${driver_id}`)
    return data
  },
  getReceipt: async (id) => {
    const data = await get(StatsRoutes.getReceipt + `?ride_id=${id}`)
    return data
  },
}

export default StatsServices;