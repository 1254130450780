import { React, useState } from 'react';
import HorizontalChart from '../Charts/BarChart/HorizontalChart';

//* MUI Imports
import { Card, CardContent, Typography } from '@mui/material';

function RidesReportCard() {
  return (
    <Card sx={{ width: "49%", my: 1, boxShadow: "none", borderRadius: "10px" }}>
      <CardContent>
        <Typography variant='h6' fontWeight="bold" >
          Rides report
        </Typography>
        <HorizontalChart />
      </CardContent>
    </Card>
  )
}

export default RidesReportCard