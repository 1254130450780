import React, { Fragment, useEffect, useState } from 'react';
//*Layout
import Layout from 'app/layout/Layout';
//*Card Components
import SimpleCard from 'app/components/Cards/SimpleCard';
import RevenueReportCard from 'app/components/Cards/RevenueReportCard';
import RidesCard from 'app/components/Cards/RidesCard';
import RidesReportCard from 'app/components/Cards/RidesReportCard';
//*Components
import colors from 'app/styles/colors';
import LastRideTable from 'app/components/DataTable/LastRideTable';
import PaymentReportTable from 'app/components/DataTable/PaymentReportTable';
import Notification from '../PushIntegration/Notification';
//*MUI Imports
import { Box, Grid } from '@mui/material';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import StatsServices from 'app/apis/Stats/StatsServices';
import { ErrorToaster } from 'app/components/Toaster/Toaster';


//* Dummy Variables for Simple cards
const totalRides = 1200;
const totalIncome = 9700;
const totalDrivers = 700;
const rides = "Total Rides";
const income = "Total Revenue";
const driver = "Total Drivers";


function Home() {
  const [stats, setStats] = useState([])


  const getStats = async () => {
    try {
      const { data } = await StatsServices.getStats()
      if (data === null) return
      setStats(data)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {

    }
  }

  useEffect(() => {
    getStats()
  }, [])
  return (
    <Fragment>
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {/* <Notification /> */}
            <SimpleCard total={stats.totalRides ? stats.totalRides : "0"} name={rides}
              icon={<DataSaverOffIcon sx={{
                backgroundColor: colors.skyBlueBg, color: colors.skyBlue, fontSize: '36px', borderRadius: "10px", padding: "10px"
              }} />} />
          </Grid>
          <Grid item xs={4}>
            <SimpleCard total={stats.totalRevenue ? `$${stats.totalRevenue}` : "0"} name={income}
              icon={<EqualizerIcon sx={{
                backgroundColor: colors.iconBg, color: colors.ufoGreen, fontSize: '36px', borderRadius: "10px", padding: "10px"
              }} />} />
          </Grid>
          <Grid item xs={4}>
            <SimpleCard total={stats.totalDrivers ? stats.totalDrivers : "0"} name={driver}
              icon={<PersonAddAltIcon sx={{
                backgroundColor: colors.opalBg, color: colors.opal, fontSize: '36px', borderRadius: "10px", padding: "10px"
              }} />} />
          </Grid>
        </Grid>
        {/* <Box display="flex" justifyContent="space-between" sx={{ pr: 0.3 }}>
          <RevenueReportCard />
          <RidesCard />
        </Box> */}

        <Box display="flex" sx={{ py: 2 }}>
          <LastRideTable />
        </Box>

        {/* <Box display="flex" justifyContent="space-between" sx={{ pr: 0.3 }}>
          <RidesReportCard />
          <PaymentReportTable />
        </Box> */}
      </Fragment>
    </Fragment>
  )
}

export default Home