import React, { Fragment } from 'react'

//*component Imports
import colors from './../../styles/colors';
import CardButton from '../Buttons/CardButton';
//Import MUI 
import { Box, Button, Card, CardContent, Chip } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Typography } from '@mui/material';

function DriverDetailCard({ title, btnView, openDocument, btnApprove, }) {

  return (
    <Card sx={{ borderRadius: 1.8, boxShadow: `0px 1px 2px 2px ${colors.shadow}` }}>
      <CardContent>
        <Box display="flex" justifyContent="center" width="100%" height="60px" alignItems="center" >
          <ContentPasteIcon sx={{
            backgroundColor: colors.iconBg, color: colors.ufoGreen,
            fontSize: '18px', borderRadius: "10px", padding: "8px",
            m: 0.5
          }} />
          <Typography variant='body2' color={colors.secondary} sx={{ width: "100px", ml: 1 }} >
            {title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent='space-evenly' sx={{ width: "100%", my: 1 }}>
          {btnView ?
            <Fragment>
              <Button
                onClick={openDocument}
                variant='contained'
                sx={{
                  width: '80px',
                  height: '24px',
                  fontSize: 12,
                  fontWeight: 'bold',
                  borderRadius: "14px",
                  textTransform: "capitalize",
                  // backgroundColor: colors.active,
                  // color: colors.ufoGreen,
                  boxShadow: 'none',
                  bgcolor: colors.active,
                  color: colors.ufoGreen,
                  "&:hover": { backgroundColor: colors.active },
                }}>
                {btnView}
              </Button>

              <Chip label={btnApprove}
                sx={{
                  width: '80px',
                  height: '24px',
                  fontWeight: 'bold',
                  fontSize: 12,
                  textTransform: "capitalize",
                  boxShadow: 'none',
                  bgcolor: btnApprove === 'required' ? colors.offline : btnApprove === 'Pending' ? colors.yellowBg : colors.ufoGreen,
                  color: btnApprove === 'required' ? colors.opal : btnApprove === 'Pending' ? colors.yellow : colors.active,
                }} />
            </Fragment>
            :
            <Chip label={"Not Uploaded"}
              sx={{
                width: '110px',
                height: '24px',
                fontWeight: 'bold',
                fontSize: 12,
                textTransform: "capitalize",
                boxShadow: 'none',
                bgcolor: colors.yellowBg,
                color: colors.yellow,
              }} />
          }

          {/* <CardButton
            setOpen={openDocument}
            title={btnView}
            bgcolor={colors.active}
            color={colors.ufoGreen}
            hover={{ backgroundColor: colors.active }}
          /> */}
          {/* <CardButton
            // setOpen={openDocument}
            // disabled={"disabled"}
            title={btnApprove}
            bgcolor={btnApprove === 'Rejected' ? colors.offline : btnApprove === 'Waiting' ? colors.yellowBg : colors.active}
            color={btnApprove === 'Rejected' ? colors.opal : btnApprove === 'Waiting' ? colors.yellow : colors.ufoGreen}
            hover={btnApprove === 'Rejected' ? {
              backgroundColor: colors.offline,
            } : btnApprove === 'Waiting' ? {
              backgroundColor: colors.yellowBg,
            } :
              {
                backgroundColor: colors.active,
              }}
          /> */}
        </Box>
      </CardContent>
    </Card>
  )
}

export default DriverDetailCard