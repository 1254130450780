
import { Box, CardMedia, CircularProgress } from '@mui/material';

export const Loading = () => {
  return (
    <Box alignItems="center" sx={{ my: 2, }}>
      <CardMedia sx={{ width: 250, height: 'auto', m: 'auto' }}>
        <CircularProgress color="success" />
      </CardMedia>
    </Box>
  );
}