import React from 'react'
import { Button } from '@mui/material';
import colors from 'app/styles/colors';

const SaveButton = (props) => {
  return (
    <Button
      {...props}
      variant='contained'
      startIcon={props.icon}
      sx={{
        mx: 1,
        width: '110px',
        height: '28px',
        fontSize: 12,
        borderRadius: "10px",
        textTransform: "capitalize",
        backgroundColor: colors.ufoGreen,
        color: colors.white,
        boxShadow: 'none',
        "&:hover": {
          backgroundColor: colors.ufoGreen,
        }
      }}>
      {props.title}
    </Button>
  )
}

export default SaveButton