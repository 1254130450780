import React from 'react'
import { useNavigate } from 'react-router-dom';
import notFound from '../../assets/images/not_found.png';

import { Box, Typography } from '@mui/material';
import { PrimaryButton } from '../Buttons/Button';



export function PageNotFound() {
  let navigate = useNavigate();
  const backToDashboard = () => {
    navigate("/")
  }
  return (
    <Box sx={{ height: '80vh', textAlign: 'center' }}>
      <Box sx={{ mt: 10 }}>
        <img src={notFound} alt="Not Found" style={{ width: "500px" }} />
        <Box>
          <Typography variant="h4" fontWeight="bold" color="initial" sx={{ pt: 4 }}>
            We can't seem to find that
          </Typography>
          <Typography variant="h6" color="initial" sx={{ pt: 4 }}>
            The Page You're Looking For Doesn't Exist Or Has Been Moved
          </Typography>
        </Box>
        <Box width='20%' sx={{ pl: "40%" }}>
          <PrimaryButton title={'Back to Dashboard'} onClick={backToDashboard} />
        </Box>
      </Box>
    </Box>
  )
}