import React from 'react'
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import colors from 'app/styles/colors';

function ComposeButton(props) {
  return (
    <Button
      variant='contained'
      {...props}
      startIcon={props.icon}
      // component={Link} to={"/mail-compose"}
      sx={{
        mx: 1,
        width: '110px',
        height: '28px',
        fontSize: 12,
        borderRadius: "8px",
        textTransform: "capitalize",
        backgroundColor: colors.ufoGreen,
        color: colors.white,
        boxShadow: 'none',
        "&:hover": {
          backgroundColor: colors.ufoGreen,
        }
      }}>
      {props.title}
    </Button>
  )
}

export default ComposeButton