import React from 'react';
import { LoadingButton } from '@mui/lab';
import colors from 'app/styles/colors';

export const PrimaryButton = (props) => {
  return (
    <LoadingButton
      variant='contained'
      {...props}
      sx={{
        width: "100%",
        textTransform: "capitalize",
        mt: 5,
        fontSize: 12,
        borderRadius: "8px",
        backgroundColor: colors.primary,
        "&:hover": {
          backgroundColor: colors.primary,
        }
      }}>
      {props.title}
    </LoadingButton>
  )
}