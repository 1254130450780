import React, { useState, useEffect } from 'react'
// import toast, { Toaster } from 'react-hot-toast';
import { ToastContainer, toast } from 'react-toastify';
import { getUserToken, onMessageListener } from './../../firebase/firebase';

function Notification() {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])

  getUserToken()

  onMessageListener()
    .then((payload) => {
      console.log("🚀 ~ file: Notification.js:28 ~ .then ~ payload", payload)
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <ToastContainer
      position="top-center"
      autoClose={10000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      limit={1}
    />
  )
}

export default Notification