const Images = {
  logo: require("./images/register_Logo.png"),
  loginLayer: require("./images/Layer2.png"),
  bgImage: require("./images/background.png"),
  rido: require("./images/Rido.png"),
  avatar: require("./images/avatar.png"),
  docImage: require("./images/doc.jpg"),
  mapImage: require("./images/map.png"),
  avatar2: require("./images/driver_avatar.png"),
  avatar3: require("./images/user_avatar.png"),
  sosImg: require("./images/sos.png"),
  sosImg2: require("./images/emergency-button.png"),
}

export default Images;