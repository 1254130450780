import React, { useCallback, useState, useEffect, Fragment } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF, Polyline, DirectionsRenderer, } from '@react-google-maps/api';
import { Box, Card } from '@mui/material';

const googleApi = "AIzaSyCk9wRPeLZuSkgyBsJVAqQSUz59VAj-CYg";


const containerStyle = {
  width: '500px',
  height: '480px',
};

// const center = {
//   lat: 24.9098747,
//   lng: 67.0770988
// };
// const marker = {
//   lat: 24.9098747,
//   lng: 67.0770988
// };
const mapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
}

function MapIntegrate({ markers, rideStatus, coords }) {
  console.log("🚀 ~ file: MapIntegrate.js:27 ~ MapIntegrate ~ rideStatus", rideStatus?.status)
  console.log("🚀 ~ file: MapIntegrate.js:27 ~ MapIntegrate ~ coords", coords)
  const [center, setCenter] = useState({
    lat: 24.9098747,
    lng: 67.0770988
  })

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApi,
  })

  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  async function calculateRoute() {
    if (window.google) {
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: markers[0],
        destination: markers[1],
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      setDirectionsResponse(results);
    }

  }
  useEffect(() => {
    if (coords?.length) {
      setCenter(() => coords[0])
    }
  }, [])
  useEffect(() => {
    calculateRoute();
  }, [window.google])

  return isLoaded ? (
    <Card sx={{ borderRadius: "18px" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={14}
        center={center}
        options={mapOptions}
        onLoad={(map) => setMap(map)}
      // defaultCenter={coords[0]}
      >
        {/* <MarkerF
          position={marker}
        />; */}
        {["Completed", "Cancelled", "Requested", "Searching"].includes(rideStatus?.status) ?
          <Fragment>
            {directionsResponse && (
              <DirectionsRenderer
                directions={directionsResponse} />
            )}
          </Fragment>
          :
          <MarkerF position={coords[0]} />

        }

      </GoogleMap>
    </Card>
  ) : <Box> Map Not Loaded</Box>
}

export default MapIntegrate