import { get, post, patch, deleted } from '../index';
import UserManagementRoutes from './UserManagement.Routes';

const UserManagementServices = {
  getAllEmployees: async (page) => {
    const data = await get(UserManagementRoutes.getAllEmployees + `?page=${page}` + `&limit=${10}`);
    return data
  },
  createEmployees: async (obj) => {
    const data = await post(UserManagementRoutes.createEmployees, obj);
    return data
  },

  updateEmployees: async (obj) => {
    const data = await patch(UserManagementRoutes.updateEmployees, obj);
    return data
  },
  deleteEmployees: async (id) => {
    const data = await deleted(UserManagementRoutes.deleteEmployees + `?id=${id}`);
    return data
  },
  resetPassword: async (id) => {
    const data = await patch(UserManagementRoutes.resetPassword + `?id=${id}`);
    return data
  },
}

export default UserManagementServices