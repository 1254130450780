// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBUPuVL-YkHdA8dJsDDuunumTJ0ZL6yRHg",
  authDomain: "rido-dashboard.firebaseapp.com",
  projectId: "rido-dashboard",
  storageBucket: "rido-dashboard.appspot.com",
  messagingSenderId: "136809437945",
  appId: "1:136809437945:web:72a06a0b75a280365f6028"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app)


export const getUserToken = () => {
  return getToken(messaging, { vapidKey: 'BFqQwejDn0938ejAsW8qykzZwQCF63XTQTz-nQzAatKm5yso4cNU0irVBsCJdkH6dNpk0ocgFcaIpcuqgD6YoWc' })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken
      }
      else {
        console.log("no auth")
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("🚀 ~ file: firebase.js:40 ~ onMessage ~ payload", payload)
      resolve(payload);
    });
  });

