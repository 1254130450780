import React from 'react'
import { Box, Typography } from '@mui/material';

function DialogPassengerDetail(props) {
  return (
    <Box sx={{ mr: 1, mt: 2.5 }}>
      <Typography sx={{ fontSize: "9px" }}>{props.title}</Typography>
      <Typography variant='body1' fontWeight='bold'>{props.text}</Typography>
    </Box>
  )
}

export default DialogPassengerDetail