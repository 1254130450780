import React from 'react'
import { Box, InputLabel, Select, Typography, MenuItem, FormControl } from '@mui/material';
import Layout from 'app/layout/Layout';
import SaveButton from 'app/components/Buttons/SaveButton';
import colors from 'app/styles/colors';
import FareAlgorithmBox from 'app/components/Box/FareAlgorithmBox';
import DoneIcon from '@mui/icons-material/Done';

function Commission() {
  return (
    <Layout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant='h5' fontWeight='bold' py={1}>COMMISSION</Typography>
        <SaveButton title={'save'} icon={<DoneIcon />} />
      </Box>
      <Box bgcolor={colors.white} borderRadius="8px" py={2}>
        {/* <Box display="flex" justifyContent="space-around" alignItems="baseline" width="46%" my={3} >
          <Typography variant='body1' sx={{ color: colors.gray }}>Commission Type :</Typography>
          <FormControl sx={{
            width: "210px", height: "2px",
            "& .MuiOutlinedInput-notchedOutline": { borderRadius: "8px" }
          }} size="small" >
            <InputLabel id="abc">Percentage</InputLabel>
            <Select
              labelId='abc'
              value={""}
              label="Percentage"
            >
              <MenuItem value={""} sx={{ borderRadius: "8px" }}>
                Percentage
              </MenuItem>
            </Select>
          </FormControl>
        </Box> */}
        <Box display="flex" justifyContent="space-around" alignItems="center" width="40%" px={3} >
          <Typography variant='body1' sx={{ flex: 1, color: colors.gray }}>Commission Type :</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: 1 }}>
            <FormControl size="small" sx={{
              my: 2,
              ml: 1,
              borderRadius: 15,
              width: "210px",
              "& .MuiOutlinedInput-notchedOutline": { borderRadius: "8px", py: 1 }
            }}>
              <InputLabel id="abc">Percentage</InputLabel>
              <Select
                labelId='abc'
                value={""}
                label="Percentage"
              >
                <MenuItem value={""} sx={{ borderRadius: "8px" }}>
                  Percentage
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <FareAlgorithmBox text={"Admin Commission"} />
        <FareAlgorithmBox text={"Commission On Ride"} />
        <FareAlgorithmBox text={"Commission Basis"} />
      </Box>
    </Layout>
  )
}

export default Commission