
import React from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

// *Import Components
import { ToasterContainer } from './components/Toaster/Toaster';
import colors from './styles/colors';
import AppRoutes from './routes/routes';
import PrivateRoutes from './routes/privateRoutes';
import useAuth from './hooks/useAuth';
import { getUserToken, onMessageListener } from './firebase/firebase';
import Notification from './views/PushIntegration/Notification';
import Layout from 'app/layout/Layout';


const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.secondary,
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: "Raleway",
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
  },

})

function App() {

  const { user } = useAuth();
  // getUserToken();

  // onMessageListener()
  //   .then((payload) => {
  //     console.log("🚀 ~ file: App.js:48 ~ .then ~ payload", payload)
  //     // setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
  //   })
  //   .catch((err) => console.log('failed: ', err));

  // useEffect(() => {
  //   verifyToken()
  // })
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {/* ========== Toaster ========== */}
        <ToasterContainer />
        { }
        <Routes>
          <Route element={user ? <Layout /> : <Navigate to="/login" />}>
            {PrivateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route element={user ? <Navigate to="/" /> : <Outlet />}>
            {AppRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
