const UserRoutes = {
  getAllPassengers: '/dashboard/passengers',
  getUserRides: "/rides/getAllRide",
  getAllDrivers: '/dashboard/drivers',
  getDriverVehicleDetail: '/dashboard/vehicleDetails',
  getDriverDocuments: '/dashboard/documents',
  updateDocumentStatus: '/dashboard/approveDocuments',
  getUsersList: '/dashboard/usersList',

  // getAllRides: '/rides/getAllRide',
  // getAllVehicles: '/dashboard/vehicles',
};

export default UserRoutes