import React, { Fragment } from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//* Components Import
import Layout from 'app/layout/Layout';
import UserServices from 'app/apis/User/UserServices';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import { Loading } from 'app/components/UI/Loader';
import { getTime } from 'app/utils/Dateformate';
import DetailsTable from 'app/components/DataTable/DetailsTable';
import { usePagination } from 'app/components/DataTable/DetailsTable';
//* MUI Imports
import { Typography, Box, TableRow, TableCell, IconButton } from '@mui/material';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import TableSort from 'app/components/Sort/TableSort';
import TripServices from 'app/apis/Trip/TripServices';
import socketService from 'app/utils/socketService';

//* Table Headers Data Array
const tableHeader = ["TripID", "Address", "Ride Start", "Ride End", "Distance", "Amount"];

function TripDetails() {
  const [allRides, setAllRides] = useState([]);
  const [loader, setLoader] = useState(false);

  //* For Pagination
  const per_Page = 10;
  const [count, setCount] = useState(1)
  const [page, setPage] = useState(1)
  const onPageChange = (pageNum) => {
    setPage(() => pageNum)
    getAllRides(pageNum)
  }

  //* Get All Rides Detail API
  const getAllRides = async (page) => {
    let obj = {
      page: page ? page : 1
    }
    try {
      const { data } = await TripServices.getAllRides(obj.page)
      if (data === null) return
      setAllRides(data.rides)
      setCount(Math.ceil(data.count / per_Page))
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    setLoader(true);
    getAllRides();
  }, []);

  return (
    <Fragment>
      <Typography variant='h5' sx={{ fontWeight: 'bold', pt: 1 }}>TRIPS REPORT</Typography>
      {/* <TableSort /> */}
      <Box>
        {loader ? (
          <Box display="flex" justifyContent="center" pl={15}>
            <Loading />
          </Box>
        ) : (
          <DetailsTable tableHeader={tableHeader} count={count} onPageChange={onPageChange} page={page}>
            {allRides && allRides?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell >{getTime(row.createdAt)}</TableCell>
                <TableCell >{getTime(row.updatedAt)}</TableCell>
                <TableCell >{row.est_distance} KM</TableCell>
                <TableCell >${row.total_amount}</TableCell>
                <TableCell>
                  <IconButton component={Link} to={`/trip-report/${row.id}`} >
                    <StickyNote2OutlinedIcon sx={{ fontSize: "20px", }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </DetailsTable>
        )}
      </Box>
    </Fragment>
  )
}

export default TripDetails