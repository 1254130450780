import { FormControl, TextField, } from "@mui/material";
import { Error } from "./Error";

export const InputField = (props) => {
  return (
    <FormControl variant="standard" fullWidth sx={{
      my: 1.5,
      borderRadius: 15
    }}>
      <TextField
        {...props}
        variant="outlined"
        size="small"
        error={props.error && true}
        InputProps={{
          endAdornment: props.InputProps?.endAdornment,
          style: { borderRadius: 8 }
        }}
        {...props.register}
      />
      {props.error &&
        <Error message={props.error} />
      }
    </FormControl>
  );
}

export const AlgorithmField = (props) => {
  return (
    <FormControl variant="standard" sx={{
      my: 1.5,
      borderRadius: 15
    }}>
      <TextField
        {...props}
        variant="outlined"
        size="small"
        error={props.error && true}
        InputProps={{
          endAdornment: props.InputProps?.endAdornment,
          style: { borderRadius: 8 }
        }}
        {...props.register}
      />
      {props.error &&
        <Error message={props.error} />
      }
    </FormControl>
  )
}
