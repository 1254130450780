import React, { Fragment, useState } from 'react';
import { sosTripData, userRows } from 'app/utils/dummyData';
import colors from 'app/styles/colors';
import DialogTripDetail from './DialogTripDetail';
import DialogPassengerDetail from './DialogPassengerDetail';
import DialogButton from '../Buttons/DialogButton';
import { baseUrl } from '../../../axios'
//* MUI Imports
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Avatar } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { getTime, getTimeDate, getDate } from 'app/utils/Dateformate';
import { Loading } from 'app/components/UI/Loader';

function TripReceipt({ open, setOpen, data }) {

  return (
    <Dialog open={open} onClose={setOpen}
      PaperProps={{
        style: {
          borderRadius: "20px",
          minWidth: "600px"
        },
      }} >
      <Box display="flex" justifyContent="space-between">
        <DialogTitle variant='h5' fontWeight="bold">Receipt</DialogTitle>
        <IconButton onClick={setOpen} sx={{ "&:hover": { backgroundColor: colors.white } }}>
          <CancelIcon sx={{ fontSize: '28px', color: colors.opal, mr: 1 }} />
        </IconButton>
      </Box>
      <DialogContent>

        {/* Passenger Detail */}
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 1,
          borderBottomColor: colors.silver,
          // py: 2
        }} >

          {/* <DialogPassengerDetail title={"Id"} text={123} />
          <DialogPassengerDetail title={"Name"} text={data.name} />
          <DialogPassengerDetail title={"Total Fare"} text={""} />
          <DialogPassengerDetail title={"Phone"} text={"Number"} /> */}
        </Box>
        {/* Trip Detail Column */}

        {/* Ride Detail */}
        {!data ? <Box display="flex" justifyContent="center" pl={25}>
          <Loading />
        </Box> :
          <Fragment>
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: 1,
              borderBottomColor: colors.silver,
              py: 1
            }} >
              <Box px={2} pt={2}>
                <Typography sx={{ fontSize: "9px" }}>Distance</Typography>
                <Typography fontSize='18px' fontWeight='bold'>
                  {data.distance}&nbsp;
                  <Typography component='span'>
                    KM
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "9px" }}>Pickup</Typography>
                <Typography sx={{ fontSize: "11px", fontWeight: "bold", pb: 2 }}>{data.pickup_address}</Typography>
                <Typography sx={{ fontSize: "9px" }}>Drop Off</Typography>
                <Typography sx={{ fontSize: "11px", fontWeight: "bold" }}>{data.dropoff_address}</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "10px", fontWeight: "bold", p: 2 }}>{getDate(data.pickup_time)}</Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: "bold", p: 2 }}>{getDate(data.dropoff_time)}</Typography>
              </Box>
            </Box>

            {/* Fare Detail */}

            <Box sx={{
              borderBottom: 0,
              borderBottomColor: colors.silver,
              pt: 2
            }} >
              <Box sx={{ display: "flex", justifyContent: "space-around", }}>
                <Typography sx={{ fontSize: "16px", mb: 1 }}>Pickup Time</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold", mb: 1 }}>{getTime(data.dropoff_time)}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-around", }}>
                <Typography sx={{ fontSize: "16px", mb: 1 }}>Drop Off Time</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold", mb: 1 }}>{getTime(data.dropoff_time)}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-around", }}>
                <Typography sx={{ fontSize: "16px", mb: 1 }} >Original Fare</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold", mb: 1 }} >{`$${data.original_fare}`}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-around", }}>
                <Typography sx={{ fontSize: "16px", mb: 1 }} >Actual Fare</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold", mb: 1 }} >{`$${data.actual_fare}`}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-around", }}>
                <Typography sx={{ fontSize: "16px", mb: 1, pr: 2 }} >+ GST</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold", mb: 1, pl: 2 }} >{`$${data.gst}`}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-around", }}>
                <Typography sx={{ fontSize: "16px", mb: 1, pr: 2.2 }} >+ Tip</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold", mb: 1, pl: 2.2 }} >{`$${data.tip}`}</Typography>
              </Box>
            </Box>
          </Fragment>
        }
      </DialogContent>

    </Dialog>
  )
}

export default TripReceipt