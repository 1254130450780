import { Box, Card, CardContent, Typography } from '@mui/material';
import { React, useState } from 'react';
import BarChart from '../Charts/BarChart/BarChart';

function RevenueReportCard() {

  return (
    <Card sx={{ width: "66.5%", my: 2, boxShadow: "none", borderRadius: "10px" }} >
      <CardContent>
        <Typography variant='h6' fontWeight="bold" >
          Revenue Report
        </Typography>
        <BarChart />
      </CardContent>
    </Card>
  )
}

export default RevenueReportCard