import React, { useState } from 'react';
import Layout from 'app/layout/Layout';
import { integration } from 'app/utils/dummyData';
import IntegrationTable, { usePagination } from 'app/components/DataTable/IntegrationTable';
import { Loading } from 'app/components/UI/Loader';
import { getTime, getDate } from 'app/utils/Dateformate';
import ComposeButton from 'app/components/Buttons/ComposeButton';
import { Link } from 'react-router-dom';
import MailDialog from 'app/components/DialogBox/MailDialog';
import colors from 'app/styles/colors';

// *MUI Imports
import { Box, Typography, TableRow, TableCell, Checkbox, IconButton, TextField, InputAdornment } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveAsOutlined from '@mui/icons-material/SaveAsOutlined';
import Search from '@mui/icons-material/Search';

const tableHeader = ["Title", "Description", "Date", "Time"]

function MailIntegration() {
  const [selected, setSelected] = useState([]);
  const [description, setDescription] = useState('');
  const [openMail, setOpenMail] = useState(false);
  const [loader, setLoader] = useState(false);

  //* For setting preload text in editor
  const mailText = (desc) => {
    setOpenMail(true)
    setDescription(desc)
  }
  //* For Pagination
  const per_Page = 10;
  // const length = integration.length;
  const count = Math.ceil(integration.length / per_Page);
  const _DATA = usePagination(integration, per_Page);
  //
  //* For Handle Select Item
  const handleClick = (e, title) => {
    const selectedIndex = selected.indexOf(title);

    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
      setSelected(newSelected)
    }
    else {
      newSelected = newSelected.concat(selected.slice(title));
      setSelected(newSelected)
    }
  }
  return (
    <Layout>
      <Typography variant='h5' sx={{ fontWeight: "bold", pt: 1 }}>MAIL INTEGRATION</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
        <Box sx={{ width: 450, borderRadius: "8px" }}>
          <TextField
            sx={{
              width: '100%', height: "34px", backgroundColor: colors.white, borderRadius: "8px",
              "& .MuiOutlinedInput-notchedOutline": { height: "34px", border: 0 },
            }}
            size='small'
            placeholder='Search'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type='submit' edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <ComposeButton title={'Compose'} icon={<SaveAsOutlined />} component={Link} to={"/mail-compose"} />
      </Box>
      <Box>
        {loader ? (
          <Loading />
        ) : (
          <IntegrationTable tableHeader={tableHeader} _DATA={_DATA} count={count}>
            {_DATA.currentData().map((row, index) => (

              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Checkbox
                    onClick={(e) => handleClick(e, row.title + index)}
                    color="primary"
                    size='small'
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell >
                  <Typography fontWeight="bold">{row.title}</Typography>
                  <Typography sx={{
                    fontSize: "11px", textOverflow: "ellipsis",
                    overflow: "hidden", whiteSpace: "nowrap", width: "500px"
                  }}>{row.desc}</Typography>
                </TableCell>
                <TableCell >{getDate(row.date)}</TableCell>
                <TableCell >{getTime(row.time)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => mailText(row.desc)}>
                    <AttachFileIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
            )}
          </IntegrationTable>
        )}
        {/*For open mail text editor */}
        <MailDialog open={openMail} setOpen={() => setOpenMail(!openMail)} description={description} />
      </Box>
    </Layout>
  )
}

export default MailIntegration