import React from 'react'
import Layout from 'app/layout/Layout'
import { Typography, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { userColumns, userRows } from 'app/utils/dummyData';

function SOSAlerts() {
  return (
    <Layout>
      <Typography variant='h5' fontWeight='bold'>SOS ALERTS</Typography>
      <Box sx={{ height: 550, width: '100%' }}>
        <DataGrid
          rows={userRows}
          columns={userColumns}
          pageSize={8}
          rowsPerPageOptions={[8]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </Layout>
  )
}

export default SOSAlerts