import React, { Fragment, useState } from 'react';
//* Components Import
import DriverDetailCard from 'app/components/Cards/DriverDetailCard';
import colors from 'app/styles/colors';
import Layout from 'app/layout/Layout';
import LegalAgreement from 'app/components/DialogBox/LegalAgreement';
import DriverDetailBox from 'app/components/Box/DriverDetailBox';
import VehicleDocument from 'app/components/DialogBox/VehicleDocument';

//* Mui Imports
import { Avatar, Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import UserServices from 'app/apis/User/UserServices';
import { Loading } from 'app/components/UI/Loader';

function DriversDetail() {
  const [openAgreement, setOpenAgreement] = useState(false);
  const [modalData, setModalData] = useState()
  // const [openDoc, setOpenDoc] = useState(false);
  const [loader, setLoader] = useState(true);
  const [driversVehicleDetail, setDriversVehicleDetail] = useState([])
  const [vehicleDocuments, setVehicleDocuments] = useState([])

  //* const [vehicleId, setVehicleId] = useState()
  const { driverId } = useParams();


  //*  API Get Driver Vehicle Data 
  const getDriverVehicleDetail = async () => {
    setLoader(true)
    try {
      const { data } = await UserServices.getDriverVehicleDetail(driverId);
      if (data === null) return
      setDriversVehicleDetail(data.vehicle)
      getDriverDocuments(data?.vehicle?.id);
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  //*  API Get  Vehicle Documents 
  const getDriverDocuments = async (vehicleId) => {
    // setLoader(true)
    try {
      const { data } = await UserServices.getDriverDocuments(vehicleId);
      if (data === null) return
      setVehicleDocuments(data?.documents)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      // setLoader(false)
    }
  }
  //* API Update Documents Status
  const updateDocumentStatus = async (driverId) => {
    // setLoader(true)
    try {
      let obj = { driver_id: driverId }
      const { data } = await UserServices.updateDocumentStatus(obj);
      if (data === null) return
      SuccessToaster("Documents Approved")
      getDriverVehicleDetail()
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      // setLoader(false)
    }
  }

  useEffect(() => {
    getDriverVehicleDetail();
  }, [])

  return (
    <Fragment>
      <Box>
        <Typography variant='h5' sx={{ fontWeight: "bold", p: 1.5 }}>DRIVER DETAILS</Typography>
        {loader ? (
          <Loading />
        ) :
          (
            <Fragment>
              <Card sx={{ borderRadius: 6, boxShadow: `0px 1px 2px 2px ${colors.shadow} ` }}>
                <CardContent sx={{ p: 2, '&:last-child': { pb: 1 } }}>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    borderBottom: 0,
                    borderBottomColor: colors.silver,
                    py: 2,
                  }} >
                    <Box>
                      <Avatar />
                    </Box>
                    <DriverDetailBox title={"Name"} text={driversVehicleDetail.user?.name} />
                    <DriverDetailBox title={"Vehicle Number"} text={driversVehicleDetail.veh_number} />
                    <DriverDetailBox title={"Vehicle Brand"} text={driversVehicleDetail.manufacture_name} />
                    <DriverDetailBox title={"Vehicle Model"} text={driversVehicleDetail.model_name} />
                    <DriverDetailBox title={"Vehicle Color"} text={driversVehicleDetail.color} />
                    <DriverDetailBox title={"Year"} text={driversVehicleDetail.year} />
                    {vehicleDocuments.filter((item) => (item.status !== "Approved")).length !== 0 ?
                      <Button
                        onClick={() => updateDocumentStatus(driverId)}
                        variant='contained'
                        sx={{
                          width: '125px',
                          height: '38px',
                          fontSize: 12,
                          fontWeight: 'bold',
                          borderRadius: "14px",
                          textTransform: "capitalize",
                          backgroundColor: colors.active,
                          color: colors.ufoGreen,
                          boxShadow: 'none',
                          "&:hover": {
                            backgroundColor: colors.active,
                          }
                        }}>Approve</Button>
                      : null}
                    {/* <DriverDetailBox title={"Vehicle ID"} text={driversVehicleDetail.id} /> */}
                    {/*<DriverDetailBox title={"Name"} text={"Guy Hawkins"} /> */}
                  </Box>
                </CardContent>
              </Card>
              {/* VehicleDocument Modals Declaration */}
              <VehicleDocument data={modalData} open={openAgreement}
                setOpen={() => { setOpenAgreement(!openAgreement); setModalData("") }} />
              {/* ====== */}
              <Grid container spacing={2} mt={1.5}>
                {vehicleDocuments.map((item, index) => (
                  <Fragment>
                    {item?.veh_documents_values?.length ?
                      <Grid key={index} item xs={3}>
                        <DriverDetailCard title={item.document_name} btnView={'View'} btnApprove={item.status}
                          openDocument={() => { setOpenAgreement(true); setModalData(item) }} />
                      </Grid>
                      :
                      <Grid key={index} item xs={3}>
                        <DriverDetailCard title={item.document_name} btnApprove={"Not Uploaded"}
                          openDocument={() => { setOpenAgreement(true); setModalData(item) }} />
                      </Grid>}
                  </Fragment>
                ))}

                {/* <Grid item xs={3}>
                  <DriverDetailCard title={vehicleDocuments[0]?.document_name}
                    btnView={'View'} btnApprove={vehicleDocuments[0].status} openDocument={() => setOpenAgreement(true)} />
                </Grid>
                <Grid item xs={3}>
                  <DriverDetailCard title={vehicleDocuments[1].document_name}
                    btnView={'View'} btnApprove={vehicleDocuments[1].status} openDocument={() => setOpenDoc(true)} />
                </Grid>
                <Grid item xs={3}>
                  <DriverDetailCard title={vehicleDocuments[2].document_name}
                    btnView={'View'} btnApprove={vehicleDocuments[2].status} />
                </Grid>
                <Grid item xs={3}>
                  <DriverDetailCard title={vehicleDocuments[3].document_name}
                    btnView={'View'} btnApprove={vehicleDocuments[3].status} />
                </Grid> */}
              </Grid>
              {/* <Grid container spacing={2} mt={1.5}>
                <Grid item xs={3}>
                  <DriverDetailCard title={vehicleDocuments[4].document_name}
                    btnView={'View'} btnApprove={vehicleDocuments[4].status} />
                </Grid>
                <Grid item xs={3}>
                  <DriverDetailCard title={vehicleDocuments[5].document_name}
                    btnView={'View'} btnApprove={vehicleDocuments[5].status} />
                </Grid>
              </Grid> */}
              <Box display="flex" justifyContent="center" my={2.5}>
                {vehicleDocuments.filter((item) => (item.status !== "Approved")).length !== 0 ?
                  <Button
                    onClick={() => updateDocumentStatus(driverId)}
                    variant='contained'
                    sx={{
                      width: '125px',
                      height: '38px',
                      fontSize: 12,
                      fontWeight: 'bold',
                      borderRadius: "14px",
                      textTransform: "capitalize",
                      backgroundColor: colors.active,
                      color: colors.ufoGreen,
                      boxShadow: 'none',
                      "&:hover": {
                        backgroundColor: colors.active,
                      }
                    }}>Approve</Button> : null}
                {/* Agreement Modals Declaration */}
                {/* <LegalAgreement data={modalData} open={openAgreement} setOpen={() => { setOpenAgreement(!openAgreement); setModalData('') }}  */}
              </Box>
            </Fragment>
          )}
      </Box>
    </Fragment>
  )
}

export default DriversDetail