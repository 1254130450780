import { Fragment, React, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

//*Components Import
import Images from 'app/assets/Images';
import colors from '../styles/colors';
import Navigation from '../Navigation';

//* MUI Imports
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Drawer, Divider } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import useAuth from 'app/hooks/useAuth';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  margin: '20px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function SideNav() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { role } = useAuth();
  //* For List Open and Expand
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [expand, setExpand] = useState([]);

  // *For Collapse
  const handleCollapse = (value) => {

    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];
    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }
    setExpand(newExpand);
  };
  //* For Collapse List Color
  const handleListItemColor = (index) => {

    const arrIndex = selectedIndex.indexOf(index)
    const newSelected = [...selectedIndex]
    if (arrIndex === -1) {
      newSelected.push(index)
    } else {
      newSelected.splice(arrIndex, 1)
    }
    setSelectedIndex(newSelected)
  }

  //*Drawer
  const drawer = (
    <Box>
      <DrawerHeader>
        <Link to='/'>
          <img src={Images.rido} alt="RidoLogo" style={{ width: "100px", marginRight: "50px" }} />
        </Link>
      </DrawerHeader>
      <List sx={{ m: 0.6, p: 0.6 }}>
        {Navigation.map((item, index) => (
          //*Collapse List Items
          < Fragment key={index} >
            {item.access.map((val) => (
              role === val ?
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  {item.children ? (
                    <Fragment>
                      <ListItemButton
                        onClick={() => {
                          item.childPath && handleCollapse(item.label);
                          setOpen(true);
                          handleListItemColor(index)
                        }}
                        selected={selectedIndex[0] === index || selectedIndex[1] === index || item.childPath.includes(pathname)}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          m: 1,
                          borderRadius: "8px",
                          "&.Mui-selected": {
                            bgcolor: colors.secondary,
                            color: colors.white,
                            ":hover": {
                              backgroundColor: colors.secondary,
                              color: colors.white,
                            }
                          },
                          ":hover": {
                            backgroundColor: colors.secondary,
                            color: colors.white,
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1 : 'auto',
                            justifyContent: 'center',
                            bgcolor: colors.white,
                            borderRadius: '6px',
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0, }} />
                        {expand.indexOf(item.label) !== -1 ?
                          <ExpandLess sx={{ opacity: open ? 1 : 0, ...(!open && { display: 'none' }) }} />
                          : <ExpandMore sx={{ opacity: open ? 1 : 0, ...(!open && { display: 'none' }) }} />}
                      </ListItemButton>
                      <Collapse in={expand.indexOf(item.label) !== -1 || item.childPath?.indexOf(pathname) !== -1 ? true : false}
                        timeout="auto" unmountOnExit sx={{ ml: 2.8 }}>
                        <List component="div" disablePadding>
                          {item.children.map((child, i) => (
                            <ListItemButton
                              key={i}
                              selected={pathname === child.path ? true : false}
                              component={Link} to={child.path}
                              sx={{
                                pl: 4, listStyleType: 'disc',
                                "&.Mui-selected": {
                                  backgroundColor: colors.white,
                                  color: colors.primary,
                                  ":hover": {
                                    backgroundColor: colors.white,
                                    color: colors.primary,
                                  }
                                },
                              }}>
                              <ListItemText primary={child.label} sx={{ display: 'list-item' }} />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {/* {role === "manager" && index === 8 ? (<Divider sx={{ my: 2 }} />) : (index === 7 && (<Divider sx={{ my: 2 }} />))} */}
                      <ListItemButton
                        selected={pathname === item.path ? true : false}
                        onClick={() => { navigate(item.path) }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          m: 1,
                          borderRadius: "8px",
                          "&.Mui-selected": {
                            bgcolor: colors.secondary,
                            color: colors.white,
                            ":hover": {
                              backgroundColor: colors.secondary,
                              color: colors.white,
                            }
                          },
                          ":hover": {
                            backgroundColor: colors.secondary,
                            color: colors.white,
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1 : 'auto',
                            justifyContent: 'center',
                            bgcolor: colors.white,
                            borderRadius: '6px',
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.label}
                          sx={{
                            opacity: open ? 1 : 0,
                          }} />
                      </ListItemButton>
                      {item.label === "Commission Report" && <Divider sx={{ my: 2 }} />}
                    </Fragment>
                  )}
                </ListItem>
                : ""))}
          </Fragment>
        ))}
      </List>
    </Box >
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRight: colors.white },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>

  )
}

export default SideNav