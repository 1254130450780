import React, { Fragment, useState } from 'react';
import { sosTripData, userRows } from 'app/utils/dummyData';
import colors from 'app/styles/colors';
import DialogTripDetail from './DialogTripDetail';
import DialogPassengerDetail from './DialogPassengerDetail';
import DialogButton from '../Buttons/DialogButton';
import { baseUrl } from '../../../axios'
//* MUI Imports
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Avatar } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { getTime, getTimeDate } from 'app/utils/Dateformate';
import { Loading } from 'app/components/UI/Loader';

function PassengerDialog({ open, setOpen, data, title, passenger }) {

  return (
    <Dialog open={open} onClose={setOpen}
      PaperProps={{
        style: {
          borderRadius: "20px",
          minWidth: "600px"
        },
      }} >
      <Box display="flex" justifyContent="space-between">
        <DialogTitle variant='h5' fontWeight="bold">{title}</DialogTitle>
        <IconButton onClick={setOpen} sx={{ "&:hover": { backgroundColor: colors.white } }}>
          <CancelIcon sx={{ fontSize: '28px', color: colors.opal, mr: 1 }} />
        </IconButton>
      </Box>
      <DialogContent>
        {/* Passenger Detail */}
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 1,
          borderBottomColor: colors.silver,
          py: 2
        }} >
          <Box>
            <Typography sx={{ fontSize: "10px", mb: 1 }} >Passenger</Typography>
            <Avatar src={baseUrl + passenger?.picture} alt="RemySharp" sx={{ mr: 2 }} />
          </Box>
          <DialogPassengerDetail title={"Id"} text={passenger?.id} />
          <DialogPassengerDetail title={"Name"} text={passenger?.name} />
          <DialogPassengerDetail title={"Gender"} text={passenger?.gender ? data?.gender : "N/A"} />
          <DialogPassengerDetail title={"Phone"} text={passenger?.phone} />
        </Box>
        {/* Trip Detail Column */}
        {!data ? (
          <Loading />
        ) : (
          <Fragment>
            {data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: 1,
                  borderBottomColor: colors.silver,
                  py: 1
                }} >
                <DialogTripDetail title={"Trip No."} text={index + 1} />
                <DialogTripDetail title={"Date/Time"} text={getTimeDate(item.createdAt)} />
                <DialogTripDetail title={"Pickup"} text={item.address} />
                <DialogTripDetail title={"Drop Off"} text={item.address} />
                <DialogTripDetail title={"Amount"} text={item.total_amount} />
                <DialogTripDetail title={"Status"} text={item.ride_status.status} color={item.ride_status.color} />
              </Box>
            ))}

          </Fragment>
        )}
        {/* Ride Detail */}
        {/* <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 1,
          borderBottomColor: colors.silver,
          py: 1
        }} >
          <Box px={2} pt={2}>
            <Typography sx={{ fontSize: "9px" }}>Distance</Typography>
            <Typography fontSize='18px' fontWeight='bold'>
              32&nbsp;
              <Typography component='span'>
                KM
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "9px" }}>Pickup</Typography>
            <Typography sx={{ fontSize: "11px", fontWeight: "bold", pb: 2 }}>Karachi 4100</Typography>
            <Typography sx={{ fontSize: "9px" }}>Drop Off</Typography>
            <Typography sx={{ fontSize: "11px", fontWeight: "bold" }}>Karachi 4100</Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "10px", fontWeight: "bold", p: 2 }}>{getTime(data?.createdAt)}</Typography>
            <Typography sx={{ fontSize: "10px", fontWeight: "bold", p: 2 }}>{getTime(data?.updatedAt)}</Typography>
          </Box>
        </Box> */}

        {/* Driver Detail */}
        {/* {title !== "Passenger Detail" &&
          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: 0,
            borderBottomColor: colors.silver,
            pt: 2
          }} >
            <Box>
              <Typography sx={{ fontSize: "10px", mb: 1 }} >Driver Details</Typography>
              <Avatar sx={{ mr: 2 }} />
            </Box>
            <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} />
            <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} />
            <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} />
          </Box>
        } */}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        {/* <DialogButton title={"View"} />
        <DialogButton title={"Later"} /> */}
      </DialogActions>
    </Dialog>
  )
}

export default PassengerDialog