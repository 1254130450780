import React, { useState } from 'react'
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import Layout from 'app/layout/Layout';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from 'app/components/Buttons/Button';
import colors from 'app/styles/colors';

function CreatePromo() {

  const [type, setType] = useState('')
  const [limit, setLimit] = useState('')
  console.log("🚀 ~ file: CreatePromo.js:11 ~ CreatePromo ~ type", type)
  const handleChange = (event) => {

    setType(event.target.value)
  };
  const handleLimit = (event) => {
    setLimit(event.target.value)
  };
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const addNewPromo = () => {

  }
  return (
    <Layout>
      <Box >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant='h5' fontWeight='bold' py={1}>Create Promotion</Typography>
        </Box>

        <Box bgcolor={colors.white} borderRadius="8px" py={3}>

          <Typography component="form" onSubmit={handleSubmit(addNewPromo)} sx={{ mt: 2 }}>
            <Box display="flex" justifyContent="space-between" >
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="Promotion Name"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.promoName?.message}
                register={register("promoName", {
                  required: "Enter Promotion Name  Name",
                  pattern: {
                    message: "Enter Promotion Name  Name",
                  },
                }
                )} />
              <TextField
                fullWidth sx={{ my: 1.5, mx: 1, }}
                label="Promotion Code"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.promoCode?.message}
                register={register("promoCode", {
                  required: "Enter Promotion Code",
                  pattern: {
                    message: "Enter Promotion Code",
                  },
                }
                )} />
            </Box>
            <Box display="flex" justifyContent="space-between" >
              <FormControl fullWidth sx={{ my: 1.5, mx: 1, }}>
                <InputLabel >Discount Type</InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  value={type}
                  label="Discount Type"
                  onChange={handleChange}
                  register={register("type", {
                    required: "Select Discount Type",
                    pattern: {
                      message: "Discount Type is required",
                    },
                  }
                  )}
                >
                  <MenuItem value={"percentage"}>Percentage</MenuItem>
                  <MenuItem value={"value"}>Value</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {type === "percentage" ?
              <Box display="flex" justifyContent="space-between" >
                <TextField
                  fullWidth sx={{ my: 1.5, mx: 1, }}
                  label="Percentage"
                  type="tel"
                  InputProps={{
                    style: { borderRadius: 8 }
                  }}
                  error={errors?.percentage?.message}
                  register={register("percentage", {
                    required: "Enter Percentage",
                    pattern: {
                      message: "Percentage is required",
                    },
                  }
                  )} />
              </Box> :
              type === "value" ?
                <Box display="flex" justifyContent="space-between" >
                  <TextField
                    fullWidth sx={{ my: 1.5, mx: 1, }}
                    label="Amount"
                    type="tel"
                    InputProps={{
                      style: { borderRadius: 8 }
                    }}
                    error={errors?.value?.message}
                    register={register("amount", {
                      required: "Enter Discount Amount",
                      pattern: {
                        message: "Discount Amount is required",
                      },
                    }
                    )} />
                </Box> : null
            }


            <Box display="flex" justifyContent="space-between" >
              <Typography alignSelf="center" alignContent="center" px={1} color={colors.gray}>
                Expire Date :
              </Typography>
              <TextField
                sx={{ my: 1.5, mx: 1, width: "80%" }}
                // label="ExpiryDate"
                type="date"
                InputProps={{
                  style: { borderRadius: 8 }
                }}
                error={errors?.date?.message}
                register={register("date", {
                  required: "Enter Promotion ExpiryDate",
                  pattern: {
                    message: "Promotion Expiry is required",
                  },
                }
                )} />
              <FormControl fullWidth sx={{ my: 1.5, mx: 1, }}>
                <InputLabel >Usage Limit</InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  value={limit}
                  label="Usage Limit"
                  onChange={handleLimit}
                  register={register("limit", {
                    required: "Select Discount Type",
                    pattern: {
                      message: "Discount Type is required",
                    },
                  }
                  )}
                >
                  <MenuItem value={"first"}>1 to 10</MenuItem>
                  <MenuItem value={"second"}>11 to 20</MenuItem>
                  <MenuItem value={"third"}>30 and above</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="center" sx={{ mb: 2, width: "100%" }}>
              <Box sx={{ mb: 2, width: "300px" }}>
                <PrimaryButton title={"Create Promo"} type="submit" />
              </Box>
            </Box>
          </Typography>
        </Box>
      </Box>
    </Layout>
  )
}

export default CreatePromo