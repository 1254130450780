import React from 'react'
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import faker from 'faker';
import colors from 'app/styles/colors';

ChartJS.register(CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Cancelled',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      barThickness: 16,
      backgroundColor: [
        colors.secondary,
      ],
      borderColor: "black",
      borderRadius: 20,
    },
    {
      label: 'Completed',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      barThickness: 16,
      backgroundColor: [
        colors.primary,
      ],
      borderColor: "black",
      borderRadius: 20,
    },
  ],
};
function BarChart() {
  return (
    <Bar options={options} data={data} />
  );
}

export default BarChart