import React, { Fragment } from 'react';
import { sosTripData, userRows } from 'app/utils/dummyData';
import colors from 'app/styles/colors';
import DialogTripDetail from './DialogTripDetail';
import DialogPassengerDetail from './DialogPassengerDetail';
import DialogButton from '../Buttons/DialogButton';
import Images from 'app/assets/Images';
//* MUI Imports
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Avatar } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CrisisAlert from '@mui/icons-material/CrisisAlert';
import { getTime } from 'app/utils/Dateformate';



function SOSDialog({ open, setOpen, data, title }) {


  return (
    <Dialog open={open}
      // onClose={setOpen}
      PaperProps={{
        style: {
          borderRadius: "20px",
          minWidth: "400px",
          maxWidth: "400px"
        },
      }} >
      <Box display="flex" justifyContent="space-between">
        <DialogTitle variant='h5' fontWeight="bold">SOS Alert
        </DialogTitle>
        <IconButton onClick={setOpen} sx={{ "&:hover": { backgroundColor: colors.white } }}>
          <CancelIcon sx={{ fontSize: '26px', color: colors.opal, mr: 1, "&:hover": { color: "red" } }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ pt: 1 }}>
        <Box display="flex" justifyContent="center">
          <img className='heartbeat' src={Images.sosImg} alt={"SosAlt"} style={{ width: "80px", }} />
          {/* <img className='heartbeat' src={Images.sosImg2} alt={"SosAlt"} style={{ width: "60px", }} /> */}
        </Box>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          borderBottom: 1,
          borderBottomColor: colors.silver,
          py: 1
        }} >
          <Typography variant='h6'>{data.title}</Typography>
        </Box>
        <Box sx={{
          display: "flex",
          justifyContent: "center",

          // borderBottom: 1,
          // borderBottomColor: colors.silver,
          py: 1
        }} >
          <Typography sx={{ fontWeight: "bold" }}>{data.body}</Typography>
        </Box>
        {/* Trip Detail Column */}
        {/* {title !== "Passenger Detail" &&
          <Fragment>
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: 1,
              borderBottomColor: colors.silver,
              py: 1
            }} >
              <DialogTripDetail title={sosTripData.title.id} text={sosTripData.tripId} />
              <DialogTripDetail title={sosTripData.title.time} text={sosTripData.time} />
              <DialogTripDetail title={sosTripData.title.name} text={sosTripData.carName} />
              <DialogTripDetail title={sosTripData.title.number} text={sosTripData.carNo} />
            </Box>
          </Fragment>
        } */}
        {/* Ride Detail */}
        {/* <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 1,
          borderBottomColor: colors.silver,
          py: 1
        }} >
          <Box px={2} pt={2}>
            <Typography sx={{ fontSize: "9px" }}>Distance</Typography>
            <Typography fontSize='18px' fontWeight='bold'>
              32&nbsp;
              <Typography component='span'>
                KM
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "9px" }}>Pickup</Typography>
            <Typography sx={{ fontSize: "11px", fontWeight: "bold", pb: 2 }}>Karachi 4100</Typography>
            <Typography sx={{ fontSize: "9px" }}>Drop Off</Typography>
            <Typography sx={{ fontSize: "11px", fontWeight: "bold" }}>Karachi 4100</Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "10px", fontWeight: "bold", p: 2 }}>{getTime(data?.createdAt)}</Typography>
            <Typography sx={{ fontSize: "10px", fontWeight: "bold", p: 2 }}>{getTime(data?.updatedAt)}</Typography>
          </Box>
        </Box> */}

        {/* Passenger Detail */}
        {/* <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 1,
          borderBottomColor: colors.silver,
          py: 2
        }} >
          <Box>
            <Typography sx={{ fontSize: "10px", mb: 1 }} >Passenger</Typography>
            <Avatar sx={{ mr: 2 }} />
          </Box>
          <DialogPassengerDetail title={"Name"} text={data?.name} />
          <DialogPassengerDetail title={"Gender"} text={data?.gender ? data?.gender : "N/A"} />
          <DialogPassengerDetail title={"Phone"} text={data?.phone} />
        </Box> */}
        {/* Driver Detail */}
        {/* {title !== "Passenger Detail" &&
          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: 0,
            borderBottomColor: colors.silver,
            pt: 2
          }} >
            <Box>
              <Typography sx={{ fontSize: "10px", mb: 1 }} >Driver Details</Typography>
              <Avatar sx={{ mr: 2 }} />
            </Box>
            <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} />
            <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} />
            <DialogPassengerDetail title={"Name"} text={"Courtney Henry"} />
          </Box>
        } */}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        {/* <DialogButton title={"View"} />
        <DialogButton title={"Later"} /> */}
      </DialogActions>
    </Dialog>
  )
}

export default SOSDialog