import Layout from 'app/layout/Layout'
import React, { Fragment } from 'react'
import { Autocomplete, Box, Button, Chip, Grid, IconButton, Typography, TextField, FormControl, InputLabel, Select, MenuItem, ListItem, ListItemIcon, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import TextEditor from 'app/components/TextEditor/TextEditor';
import { InputField } from 'app/components/UI/TextField';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveButton from 'app/components/Buttons/SaveButton';
import colors from 'app/styles/colors';
import ComposeButton from 'app/components/Buttons/ComposeButton';
import NotificationServices from 'app/apis/Notification/NotificationServices';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import UserServices from 'app/apis/User/UserServices';
import { Error } from 'app/components/UI/Error';

function MailCompose() {

  const [message, setMessage] = useState("")
  //* Handle Users For Email Sending
  const [role, setRole] = useState('')

  const handleRole = (event) => {
    setRole(event.target.value);
  };
  //* For Handle Multiple selected Emails
  const [passengerMail, setPassengerMail] = useState([]);

  const [driverMail, setDriverMail] = useState([])

  const handlePassengerChange = (event) => {
    const {
      target: { value },
    } = event;
    setPassengerMail(
      // On autofill we get a stringified value.
      // typeof value === 'string' ? value.split(',') : value,
      value
    );
  };

  const handleDriverChange = (event) => {
    const {
      target: { value },
    } = event;
    setDriverMail(
      // On autofill we get a stringified value.
      // typeof value === 'string' ? value.split(',') : value,
      value
    );
  };
  //* For Get Users Email Address
  // const [usersList, setUsersList] = useState([])
  const [passengerList, setPassengerList] = useState([])
  const [driverList, setDriverList] = useState([])

  const getUsersList = async () => {
    try {
      const { data } = await UserServices.getUsersList();
      if (data === null) return
      setPassengerList(data.passengers)
      setDriverList(data.drivers)
      // setUsersList([...data.passengers, ...data.drivers])
    } catch (error) {
      ErrorToaster(error)
    }
  }
  useEffect(() => {
    getUsersList()
  }, [])
  // *For Sending Email
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();

  const getTextEditorValue = (value) => {
    setMessage(value)

  }
  const sendEmail = async (formData) => {
    // setLoader(true)
    try {
      let obj = {
        subject: formData.title,
        message: message,
        users: [...formData.emailDriver, ...formData.emailPassenger],
      }

      const { data } = await NotificationServices.sendEmail(obj);
      if (data === null) return
      SuccessToaster("Email Send Successfully")
      reset()
      setTimeout(() => (window.location.reload(false)), 1000)

    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      // setLoader(false)
    }
  }


  return (
    <Fragment>
      <Typography id="mail-form" component="form" onSubmit={handleSubmit(sendEmail)} sx={{ mt: 6 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant='h5' fontWeight='bold' py={1}>MAIL INTEGRATION</Typography>
          <ComposeButton type="submit" title={'Send'} icon={<SendIcon />} />
          {/* <SaveButton title={'Send'} icon={<SendIcon />} /> */}
        </Box>
        <Box bgcolor={colors.white} p={2}>
          <Grid container >
            <Grid item xs={3} px={2} alignSelf="center">
              <Typography sx={{ my: 2 }}>Title :</Typography>
            </Grid>
            <Grid item xs={6} px={2}>
              <InputField
                label="Title"
                type="title"
                error={errors?.title?.message}
                register={register("title", {
                  required: "Please fill title field.",
                  pattern: {
                    message: "Please fill title field.",
                  },
                })} />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={3} px={2}>
              <Typography sx={{ my: 2 }}>Description :</Typography>
            </Grid>
            <Grid item xs={5} px={2}>
              <TextEditor getValue={getTextEditorValue} />
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid item xs={3} px={2} alignSelf="center">
              <Typography sx={{ my: 2 }}>Select User :</Typography>
            </Grid>
            <Grid item xs={6} px={2}>
              <FormControl fullWidth sx={{ my: 1.5, }}>
                <InputLabel >Role</InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  value={role}
                  label="Role"
                  onChange={(e, value) => {
                    handleRole(e)
                    setValue("role", e.target.value)

                  }}
                  inputProps={register("role", {
                    required: "Select user's Role",
                    pattern: {
                      message: "User Role is required",
                    },
                  }
                  )}
                >
                  <MenuItem value={"passenger"}>Passenger</MenuItem>
                  <MenuItem value={"driver"}>Driver</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid container >
            <Grid item xs={3} px={2} alignSelf="center">
              <Typography sx={{ my: 2 }}>Passenger Email :</Typography>
            </Grid>
            <Grid item xs={6} px={2}>
              <FormControl fullWidth sx={{ my: 1.5, borderRadius: "12px" }}>
                <InputLabel>Email</InputLabel>
                <Select
                  multiple
                  value={passengerMail}
                  onChange={handlePassengerChange}
                  input={<OutlinedInput label="Email" />}
                  renderValue={(selected) => selected.join(', ')}
                  inputProps={register("emailPassenger", {
                    required: {
                      value: !driverMail.length && !passengerMail.length,
                      message: "Please select user email.",
                    },
                    pattern: {
                      message: "Please select email address.",
                    },
                  })}
                  sx={{ borderRadius: "8px" }}
                >
                  {passengerList.map((item, index) => (
                    <MenuItem key={index} value={item.email}>
                      <Checkbox checked={passengerMail.indexOf(item.email) > -1} />
                      <ListItemText primary={`${item.id}-${item.name}`} />
                    </MenuItem>
                  ))}
                </Select>
                <Error message={errors?.passengerMail?.message} />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} px={2} alignSelf="center">
              <Typography sx={{ my: 2 }}>Driver Email :</Typography>
            </Grid>
            <Grid item xs={6} px={2}>
              <FormControl fullWidth sx={{ my: 1.5, borderRadius: "12px" }}>
                <InputLabel>Email</InputLabel>
                <Select
                  multiple
                  value={driverMail}
                  onChange={handleDriverChange}
                  input={<OutlinedInput label="Email" />}
                  renderValue={(selected) => selected.join(', ')}
                  inputProps={register("emailDriver", {
                    required: {
                      value: !driverMail.length && !passengerMail.length,
                      message: "Please select user email.",
                    },
                    pattern: {
                      message: "Please select email address.",
                    },
                  })}
                  sx={{ borderRadius: "8px" }}
                >
                  {driverList.map((item, index) => (
                    <MenuItem key={index} value={item.email}>
                      <Checkbox checked={driverMail.indexOf(item.email) > -1} />
                      <ListItemText primary={`${item.id}-${item.name}`} />
                    </MenuItem>
                  ))}
                </Select>
                <Error message={errors?.emailDriver?.message} />
              </FormControl>
            </Grid>
          </Grid>

          {/* <Autocomplete fullWidth sx={{my: 1.5, borderRadius: "12px" }} multiple id="email-tags" options={usersList}
                getOptionLabel={(option) => option?.email}
                filterSelectedOptions
                onChange={(event, value) => { setValue("email", value); console.log("target here =====>", value) }}
                renderInput={(params) => (
                  <TextField register={register("email")}  error={errors?.email?.message}  {...params}  label="Select Mails" placeholder="@email.com" />
                )}/> */}
          {/* <InputField
                label="Email"
                type="email"
                error={errors?.email?.message}
                register={register("email", {
                  required: "Please enter email address.",
                  pattern: {
                    message: "Please enter email address.",
                  },
                }
                )} /> */}
          {/* <Grid container >
          
          <Grid item xs={3} px={2} alignSelf="center">
            <Typography sx={{ my: 2 }}>Date :</Typography>
          </Grid>
          <Grid item xs={6} px={2}>
            <InputField />
          </Grid>
        </Grid>
        <Grid container >
          <Grid item xs={3} px={2}>
            <Typography sx={{ my: 2 }}>Audience :</Typography>
          </Grid>
          <Grid item xs={2} px={2}>
            <Box display="flex" my={1}>
              <Chip label="Passengers" variant="outlined" sx={{
                width: "200px",
                borderRadius: "8px",
              }} />
              <IconButton>
                <AddIcon sx={{ color: colors.ufoGreen }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item xs={3} px={2}>
            <Typography sx={{ my: 1 }}>Attachment :</Typography>
          </Grid>
          <Grid item xs={2} px={2}>
            <IconButton>
              <AttachFileIcon sx={{ fontSize: "16px" }} />
              <Typography>Image 01</Typography>
            </IconButton>
            <IconButton>
              <AddIcon sx={{ color: colors.ufoGreen }} />
            </IconButton>
          </Grid>
        </Grid> */}
        </Box>
      </Typography>
    </Fragment>
  )
}

export default MailCompose