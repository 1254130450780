import React, { useState } from 'react';
import { Box, TextField, InputAdornment, IconButton, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import colors from 'app/styles/colors';
import moment from 'moment';

function TableSort({ getDates, driverList }) {
  const [toDate, setToDate] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [driverId, setDriverId] = useState('')
  const callFunction = () => {
    getDates(toDate, fromDate, driverId)
    setToDate("")
    setFromDate("")
    setDriverId("")
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 3 }}>
      {/* <Box>
        <ListIcon sx={{ color: colors.gray, backgroundColor: colors.white, borderRadius: '8px', p: 0.5, m: 0.2 }} />
      </Box> */}
      {/* <Box sx={{ width: 450, borderRadius: "8px" }}>

        <TextField
          sx={{
            width: '100%', height: "34px", backgroundColor: colors.white, borderRadius: "8px",
            "& .MuiOutlinedInput-notchedOutline": { height: "34px", border: 0 },
          }}
          size='small'
          placeholder='Search'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type='submit' edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box> */}
      <Box sx={{ display: 'flex', alignContent: "center" }}>
        <Box display="flex" alignItems="center">
          <Typography sx={{ fontSize: "11px", fontWeight: "bold", color: colors.gray }}>Sort By Date:</Typography>
        </Box>
        <FormControl sx={{
          width: "120px", height: "34px", mx: 1, backgroundColor: colors.white,
          borderRadius: "8px",
          "& .MuiInputLabel-root": { fontSize: "12px" },
          "& .MuiOutlinedInput-notchedOutline": { border: 0 }
        }} size="small" >
          <InputLabel id="demo-simple-select-label">From Date</InputLabel>
          <Select
            value={fromDate}
            label="From Date"
            renderValue={() => fromDate}
          >
            <MenuItem>
              <TextField
                onChange={(e) => (setFromDate(e.target.value))}
                size="small"
                // label="Select Date"
                type="date"
                InputProps={{
                  style: { borderRadius: 8 }
                }} />
            </MenuItem>
            {/* <MenuItem
              onClick={() => setDateValue(moment().startOf('week').format('YYYY-MM-DD HH:MM:SS'))}
              value={""} type="date">
              Last Week
            </MenuItem>
            <MenuItem
              // onClick={() => (console.log(moment().startOf('week').format('YYYY-MM-DD HH:MM:SS')))}
              value={""} type="date">
              Last Month
            </MenuItem> */}

          </Select>
        </FormControl>
        <FormControl sx={{
          width: "120px", height: "34px", mx: 1, backgroundColor: colors.white,
          borderRadius: "8px",
          "& .MuiInputLabel-root": { fontSize: "12px" },
          "& .MuiOutlinedInput-notchedOutline": { border: 0 }
        }} size="small" >
          <InputLabel id="demo-simple-select-label">To Date</InputLabel>
          <Select
            value={toDate}
            label="To Date"
            renderValue={() => toDate}
          >
            <MenuItem>
              <TextField
                onChange={(e) => (setToDate(e.target.value))}
                size="small"
                // label="Select Date"
                type="date"
                InputProps={{
                  style: { borderRadius: 8 }
                }} />
            </MenuItem>
          </Select>
        </FormControl>
        {/* <FormControl sx={{
          width: "80px", height: "34px", mx: 1, backgroundColor: colors.white,
          borderRadius: "8px",
          "& .MuiFormControl-root": {},
          "& .MuiInputLabel-root": { fontSize: "12px" },
          "& .MuiOutlinedInput-notchedOutline": { border: 0 }
        }} size="small" >
          <InputLabel id="demo-simple-select-label">Trip ID</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={""}
            label="Trip"
          >
            <MenuItem value={""}>trip</MenuItem>
          </Select>
        </FormControl> */}
      </Box>
      <Box sx={{ display: 'flex', alignContent: "center" }}>
        <Box display="flex" alignItems="center">
          <Typography sx={{ fontSize: "11px", fontWeight: "bold", color: colors.gray }}>Sort By Driver:</Typography>
        </Box>
        <FormControl sx={{
          width: "120px", height: "34px", mx: 1, backgroundColor: colors.white,
          borderRadius: "8px",
          "& .MuiFormControl-root": {},
          "& .MuiInputLabel-root": { fontSize: "12px" },
          "& .MuiOutlinedInput-notchedOutline": { border: 0 }
        }} size="small" >
          <InputLabel id="demo-simple-select-label">Driver</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={driverId}
            label="Rating"
          >
            {driverList?.map((item, index) => (
              <MenuItem key={index} value={item?.id} onClick={() => (setDriverId(item.id))}>
                {`${item?.id}-${item.name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={callFunction} >
          <SearchIcon sx={{ color: colors.gray }} />
        </Button>
      </Box>
    </Box>
  )
}

export default TableSort