import { get, post, patch, deleted } from '../index';
import UserRoutes from './User.Routes';

const UserServices = {
  getAllPassengers: async () => {
    const data = await get(UserRoutes.getAllPassengers);
    return data
  },
  getUserRides: async (passengerId) => {
    let pageNo = 1
    const data = await get(UserRoutes.getUserRides + `?passenger_id=${passengerId}` + `&page=${pageNo}` + `&limit=${5}`)
    return data
  },
  getAllDrivers: async () => {
    const data = await get(UserRoutes.getAllDrivers);
    return data
  },
  // getAllRides: async () => {
  //   const data = await get(UserRoutes.getAllRides);
  //   return data
  // },

  // getAllVehicles: async () => {
  //   const data = await get(UserRoutes.getAllVehicles);
  //   return data
  // },
  getDriverVehicleDetail: async (driverId) => {
    const data = await get(UserRoutes.getDriverVehicleDetail + `?driver_id=${driverId}`);
    return data
  },
  getDriverDocuments: async (vehicleId) => {
    const data = await get(UserRoutes.getDriverDocuments + `?vehicle_id=${vehicleId}`);
    return data
  },

  updateDocumentStatus: async (obj) => {
    const data = await patch(UserRoutes.updateDocumentStatus, obj);
    return data
  },

  getUsersList: async () => {
    const data = await get(UserRoutes.getUsersList);
    return data
  },
}

export default UserServices