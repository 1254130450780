import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';
//*Components Import
import Layout from 'app/layout/Layout'
import UserServices from 'app/apis/User/UserServices';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import { getDate } from 'app/utils/Dateformate';
import colors from 'app/styles/colors';
import { Loading } from 'app/components/UI/Loader';
import UserTable, { usePagination } from 'app/components/DataTable/UserTable';
import TableSort from 'app/components/Sort/TableSort';
import { baseUrl } from '../../../../axios'
//* MUI Imports
import { Box, Typography, TableRow, TableCell, Avatar, IconButton, } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

//* Table Header Array
const tableHeader = ["ID", "Name", "Email", "Phone No", "Gender", "Status", "Online Status", "Driving Test"];

function Drivers() {
  const [allDrivers, setAllDrivers] = useState([])
  const [loader, setLoader] = useState(false);
  //* For Pagination
  const per_Page = 10;
  const count = Math.ceil(allDrivers.length / per_Page)
  const _DATA = usePagination(allDrivers, per_Page);

  //*  API Get Drivers Data 
  const getAllDrivers = async () => {
    setLoader(true)
    try {
      const { data } = await UserServices.getAllDrivers();

      if (data === null) return
      setAllDrivers(data.result)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getAllDrivers();
  }, [])

  return (
    <Fragment>
      <Typography variant='h5' sx={{ fontWeight: 'bold', pt: 1 }}>DRIVERS REPORT</Typography>
      {/* <TableSort /> */}
      <Box >
        {loader ? (
          <Box display="flex" justifyContent="center" pl={15}>
            <Loading />
          </Box>
        ) :
          (
            <UserTable tableHeader={tableHeader} _DATA={_DATA} count={count}>
              {_DATA && _DATA.currentData()?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell >{row.id}</TableCell>
                  <TableCell component="th" scope="row">
                    <Box display="flex" alignItems="center">
                      <Avatar src={baseUrl + row.picture} alt="RemySharp"
                        sx={{ width: 32, height: 32, mr: 1 }} />
                      {row.name}
                    </Box>
                  </TableCell>
                  <TableCell >{row.email}</TableCell>
                  <TableCell >{row.phone}</TableCell>
                  <TableCell >{row.gender}</TableCell>
                  <TableCell >
                    {row.is_active === true ?
                      <Typography bgcolor={colors.active} color={colors.ufoGreen} sx={{
                        borderRadius: "8px",
                        width: "80px",
                        fontSize: "14px",
                        textAlign: "center",
                      }}>Active</Typography> :
                      <Typography
                        bgcolor={colors.offline} color={colors.opal} sx={{
                          borderRadius: "8px",
                          width: "80px",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >Offline</Typography>
                    }
                  </TableCell>

                  <TableCell >
                    {row.is_online === true ? (
                      <Typography bgcolor={colors.active} color={colors.ufoGreen} sx={{
                        borderRadius: "8px",
                        width: "80px",
                        fontSize: "14px",
                        textAlign: "center",
                      }}>Online</Typography>) : (
                      <Typography
                        bgcolor={colors.offline} color={colors.opal} sx={{
                          borderRadius: "8px",
                          width: "80px",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >Offline</Typography>)
                    }
                  </TableCell>

                  {row.test_pass === null ? (
                    <TableCell>Test Not Conducted</TableCell>
                  ) : (
                    <TableCell >
                      {row.test_pass === true ?
                        <Typography bgcolor={colors.active} color={colors.ufoGreen} sx={{
                          borderRadius: "8px",
                          width: "80px",
                          fontSize: "14px",
                          textAlign: "center",
                        }}>Passed</Typography> :
                        <Typography
                          bgcolor={colors.offline} color={colors.opal} sx={{
                            borderRadius: "8px",
                            width: "80px",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >Failed</Typography>
                      }
                    </TableCell>
                  )}

                  <TableCell >
                    {/* <IconButton>
                      <RoomIcon style={{ color: colors.opal }} />
                    </IconButton> */}
                    <IconButton component={Link} to={`/driver-detail/${row.id}`}>
                      <StickyNote2OutlinedIcon sx={{ color: colors.lightSecondary }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </UserTable>
          )}
      </Box>
    </Fragment>
  )
}

export default Drivers