import React from 'react';
import colors from 'app/styles/colors';
//* MUI Imports
import { Box, Card, CardContent, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function SimpleCard(props) {

  return (
    <Card display="flex" sx={{ borderRadius: "10px", boxShadow: `0px 1px 2px 2px ${colors.shadow} ` }} >
      <CardContent sx={{ p: 2, '&:last-child': { pb: 1 } }}>
        <Box display="flex" justifyContent="space-between">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color={colors.secondary}>
              {props.name}
            </Typography>
            <Typography variant='h5' fontWeight="bold" fontFamily="Roboto">{props.total}</Typography>
            <Typography fontFamily="Roboto" color={colors.ufoGreen}>
              <ArrowUpwardIcon sx={{ color: colors.ufoGreen, fontSize: '18px' }} />
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {props.icon}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default SimpleCard