import React from 'react'
import { useState } from 'react';
import colors from 'app/styles/colors'
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import AuthLayout from './AuthLayout';
import { PrimaryButton } from 'app/components/Buttons/Button';
import { InputField } from 'app/components/UI/TextField';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
//* Import MUI
import { Box, Typography, Checkbox, FormGroup, FormControlLabel, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  loginBox: {
    backgroundColor: colors.white,
    borderRadius: "8px",
    boxShadow: `0px 20px 24px ${colors.shadow}`,
  },
  link: {
    textDecoration: "none",
    color: colors.secondary,
    marginLeft: "6px",
  },
  signUp: {
    textDecoration: "none",
    color: colors.primary,
    marginLeft: "6px",
  },
  input: {
    height: "36px",
    padding: "0px 14px",
  }
})


function Register() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  // *For Show Password Toggle
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm();

  // *For Submit Register Form
  const createAccount = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        fullName: formData.fullName,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      }
      console.log("🚀 ~ file: Register.js:67 ~ onSignUp ~ obj", obj)
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  return (
    <AuthLayout>

      <Box className={classes.loginBox}
        sx={{ mt: 2, mx: 1, py: 4, px: 3 }}>
        <Typography variant='h5' sx={{ textAlign: "center", my: 4, fontWeight: 'bold' }}>
          Create Account
        </Typography>
        <Typography component="form"
          onSubmit={handleSubmit(createAccount)}
          sx={{ mt: 5 }}>

          <InputField
            label="Full Name"
            type="text"
            error={errors?.fullName?.message}
            register={register("fullName", {
              required: "Please enter your name.",
              pattern: {
                message: "Please enter your name for registration.",
              },
            }
            )}
          />

          <InputField
            label="Email"
            type="email"
            error={errors?.email?.message}
            register={register("email", {
              required: "Please enter your name.",
              pattern: {
                message: "Please enter your name for registration.",
              },
            }
            )}
          />

          <InputField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            error={errors?.password?.message}
            register={register("password", {
              required: "Please enter password.",
              minLength: {
                value: 6,
                message: "Password must have at least 6 characters.",
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <InputField
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            error={errors?.confirmPassword?.message}
            register={register("confirmPassword", {
              required: "Please re-type your password.",
              minLength: {
                value: 6,
                message: "Password must have at least 6 characters.",
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end">
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormGroup >
            <FormControlLabel
              control={<Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />}
              label={<Typography variant="body2" color={colors.secondary}>
                <span>I Accept</span>
                <span><Link className={classes.signUp}>
                  Terms and Conditions
                </Link></span>
              </Typography>}
            />
          </FormGroup>

          <PrimaryButton type="submit"
            loading={loading}
            title={'Register'} />

          <Box sx={{ my: 2 }}>
            <Typography
              variant="body1"
              color={colors.secondary}
              sx={{ textAlign: "center" }}
            >
              Already have an account?
              <Link to="/login" className={classes.signUp}>
                Login
              </Link>
            </Typography>
          </Box>
        </Typography>
      </Box>
    </AuthLayout>
  )
}

export default Register