import { get, post, patch, deleted } from '../index';
import VehicleRoutes from './Vehicle.Routes';

const VehicleServices = {
  getAllVehicles: async () => {
    const data = await get(VehicleRoutes.getAllVehicles)
    return data
  }
}

export default VehicleServices