import React from 'react'
import { Box, Dialog, Typography, DialogTitle, DialogContent, DialogActions, IconButton, Button, FormControlLabel, Checkbox } from '@mui/material';
import TextEditor from 'app/components/TextEditor/TextEditor';
import colors from 'app/styles/colors';
import CloseIcon from '@mui/icons-material/Close';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';

function MailDialog({ open, setOpen, description }) {

  return (
    <Box>
      <Dialog open={open} onClose={setOpen}
        PaperProps={{
          style: {
            borderRadius: "12px"
          },
        }} >
        <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: colors.secondary }} >
          <DialogTitle variant='h6' sx={{ color: "white", pb: 1 }} >Mail</DialogTitle>
          <IconButton onClick={setOpen} sx={{ "&:hover": { backgroundColor: colors.secondary } }}>
            <CloseIcon sx={{ fontSize: '28px', color: colors.white, mr: 1 }} />
          </IconButton>
        </Box>
        <DialogContent>
          <Box display="flex">
            <Typography sx={{ px: 1 }}>Subject :</Typography>
            <Typography sx={{ px: 2, fontWeight: "bold" }}>Lorem Ipsum</Typography>
          </Box>
          <Box display="flex">
            <Typography sx={{ px: 1 }}>To :</Typography>
            <Typography sx={{ px: 5, fontWeight: "bold" }}>All Users</Typography>
          </Box>
          <Box display="flex" my={1}>
            <TextEditor description={description} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", m: 1 }}>
          <FormControlLabel control={<Checkbox />} label="Save As Draft"
            sx={{ fontSize: "6px" }} />
          <Button
            variant='contained'
            startIcon={<NearMeOutlinedIcon />}
            sx={{
              mx: 1,
              width: '110px',
              height: '28px',
              fontSize: 12,
              borderRadius: "10px",
              textTransform: "capitalize",
              backgroundColor: colors.secondary,
              color: colors.white,
              boxShadow: 'none',
              "&:hover": {
                backgroundColor: colors.secondary,
              }
            }}>
            Resend
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default MailDialog