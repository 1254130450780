import React, { Fragment } from 'react'
import { Box } from '@mui/material';
import SendbirdApp from "@sendbird/uikit-react/App";
import "@sendbird/uikit-react/dist/index.css";

const myColorSet = {
  '--sendbird-light-primary-500': '#368738',
  '--sendbird-light-primary-400': '#f6f6f6',
  '--sendbird-light-primary-300': '#368738',
  '--sendbird-light-primary-200': 'rgb(0, 200, 10)',
  '--sendbird-light-primary-100': '#d5f7e9',

};
function ChatSupports() {
  return (
    <Fragment>
      <Box sx={{ height: "500px", width: '1000px' }}>
        <SendbirdApp
          appId={"142D26C5-A4C7-4650-B0FD-9246FECDBE65"}
          userId={"0100"}
          colorSet={myColorSet}
          showSearchIcon={false}
          isReactionEnabled={false}
          disableMarkAsDelivered={false}
          isTypingIndicatorEnabledOnChannelList={true}
          isMessageReceiptStatusEnabledOnChannelList={false}
          useMessageGrouping={false}
        />
      </Box>
    </Fragment>
  )
}

export default ChatSupports