import React, { useEffect } from 'react';
import { useState } from 'react';
//* Component imports
import colors from 'app/styles/colors';
import UserServices from 'app/apis/User/UserServices';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import { getDate } from 'app/utils/Dateformate';
import { baseUrl } from '../../../axios';
//* MUI Imports
import { styled } from '@mui/material/styles';
import { Box, Typography, Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar } from '@mui/material';
import { Loading } from 'app/components/UI/Loader';
import TripServices from 'app/apis/Trip/TripServices';

//* Data Table Header Styling
const StyledTableHead = styled(TableHead)(() => ({
  border: 0,
  '& .MuiTableCell-head': {
    border: 0,
  },
}));
//* Data Table Body Styling
const StyledTableBody = styled(TableBody)(() => ({
  border: 0,
  '& .MuiTableCell-body': {
    border: 0,
    fontWeight: 700,
    fontSize: '12px', minHeight: '32px',
    lineHeight: '17px',
    color: `${colors.secondary}`,
    boxSizing: 'none', borderBottom: 'none',
  },
}));

//* Table Header Data
const tableHeader = ["Ride ID", "Passenger Name", "Address", "Destination", "Distance", "Date", "Status"]
function LastRideTable() {

  const [allRides, setAllRides] = useState([])
  const [loader, setLoader] = useState(false);

  //* Get All Rides Detail API
  const getAllRides = async () => {

    try {
      setLoader(true)
      const { data } = await TripServices.getAllRides(1)
      if (data === null) return
      setAllRides(data.rides)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }
  const findLastFive = allRides?.slice(0, 4)

  useEffect(() => {
    getAllRides();
  }, []);

  return (
    <Card sx={{ width: '100%', borderRadius: '10px', boxShadow: `0px 1px 2px 2px ${colors.shadow}`, }}>
      <CardContent sx={{ width: '100%', px: 1 }}>
        <Typography variant='h6' fontWeight="bold">Last Ride</Typography>
        <Box>
          {loader ? (
            <Box display="flex" justifyContent="center" pl={15}>
              <Loading />
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    {tableHeader.map((item, index) => (
                      <TableCell key={index}>{item}</TableCell>
                    ))}
                  </TableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {findLastFive?.map((row, index) => {
                    if (index < 4) {
                      return (
                        <TableRow
                          key={index}
                        >
                          <TableCell >{row.id}</TableCell>
                          {/* <TableCell component="th" scope="row">
                            <Box display="flex" alignItems="center">
                              <Avatar src={baseUrl + row.passenger.picture} alt="RemySharp"
                                sx={{ width: 32, height: 32, mr: 1 }} />
                              {row.passenger.name}
                            </Box>
                          </TableCell> */}
                          <TableCell >{row.passenger?.name}</TableCell>
                          <TableCell >{row.address}</TableCell>
                          <TableCell >{row?.ride_destinations[row.ride_destinations.length - 1]?.address}</TableCell>
                          <TableCell >{row.est_distance} KM</TableCell>
                          <TableCell >{getDate(row.createdAt)}</TableCell>
                          <TableCell >
                            <Typography sx={{ color: `${row.ride_status.color}`, fontWeight: "bold" }}>
                              {row.ride_status.status}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  }
                  )}
                </StyledTableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export default LastRideTable