import React, { Fragment } from 'react'
//*Components Import
import { Error } from 'app/components/UI/Error';
import colors from 'app/styles/colors';
//* MUI Imports
import { Box, TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { FormControl } from '@mui/material';
import HistoryToggleOff from '@mui/icons-material/HistoryToggleOff';

function FareAlgorithmBox(props) {

  return (
    <Box display="flex" justifyContent="space-around" alignItems="center" width="40%" px={3} >
      <Typography variant='body1' sx={{ flex: 1, color: colors.gray }}>{props?.text} :</Typography>
      {props?.text === "Charge On Time" ? (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: 1 }}>
          <Typography>$</Typography>
          <FormControl variant="standard" sx={{
            my: 2,
            ml: 1,
            "& .MuiOutlinedInput-root": { borderRadius: "8px" }
          }}>
            <TextField
              {...props}
              value="0.50"
              variant="outlined"
              size="small"
              error={props.error && true}
              InputProps={{
                endAdornment: props.InputProps?.endAdornment,
                style: { borderRadius: 8 }
              }}
              {...props.register}
            />
            {props.error &&
              <Error message={props?.error} />
            }
          </FormControl>
          <FormControl variant="standard" sx={{
            my: 2,
            ml: 1,
            "& .MuiOutlinedInput-root": { borderRadius: "8px" }
          }}>
            <TextField
              {...props}
              value="2"
              variant="outlined"
              size="small"
              error={props.error && true}
              InputProps={{
                endAdornment: props.InputProps?.endAdornment,
                style: { borderRadius: 8 }
              }}
              {...props.register}
            />
            {props.error &&
              <Error message={props?.error} />
            }
          </FormControl>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: 1 }}>
          {["Commission On Ride", "Commission", "GST"].includes(props?.text) ? <Typography>%</Typography> :
            ["Free Minutes", "Minimum Waiting Minutes"].includes(props.text) ? <HistoryToggleOff sx={{ fontSize: "20px", color: colors.gray }} /> :
              <Typography>$</Typography>}
          <FormControl variant="standard" sx={{ px: 1, my: 2, "& .MuiOutlinedInput-root": { borderRadius: "8px", } }}>
            <TextField
              defaultValue={props?.data}
              variant="outlined"
              size="small"
              {...props.register}
            />
            {props?.error &&
              <Error message={props?.error} />
            }
          </FormControl>
        </Box>
      )}
    </Box>
  )
}

export default FareAlgorithmBox