import Register from './../views/Auth/Register';
import ForgetPassword from './../views/Auth/ForgetPassword';
// import ResetPassword from './../views/Auth/ResetPassword';
import Login from './../views/Auth/Login';

const AppRoutes = [
  {
    path: '/register',
    component: <Register />
  },
  {
    path: '/login',
    component: <Login />
  },
  {
    path: '/forgot-password',
    component: <ForgetPassword />
  },
  // {
  //   path: '/reset-password',
  //   component: <ResetPassword />
  // },
]

export default AppRoutes;