import React, { useState } from 'react'
//* Components Import
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
// *MUI Imports
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, Pagination, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { userRows } from 'app/utils/dummyData';
import { Campaign } from "@mui/icons-material";
import TableSort from 'app/components/Sort/TableSort';
import { useNavigate } from 'react-router-dom';

//* Data Table Styling
const StyledTableHead = styled(TableHead)(() => ({
  border: 0,
  '& .MuiTableCell-head': {
    minHeight: '16px',
    padding: '14px'
  },
}));

const StyledTableBody = styled(TableBody)(() => ({

  '& .MuiTableCell-body': {
    fontWeight: 600,
    fontSize: '12px', minHeight: '16px',
    lineHeight: '16px',
    color: `${colors.secondary}`,
    boxSizing: 'none',
  },
}));

//* Logic for Pagination
function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

//* Table Header Array
const tableHeader = ["Promo Name", "Promo Code", "Discount percent", "Discount value", "Expiry date", "Limit", "Status"];
function PromotionsList() {
  const navigate = useNavigate();
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(userRows.length / PER_PAGE);
  const _DATA = usePagination(userRows, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <Layout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant='h5' fontWeight='bold' py={1}>Promotions List</Typography>
        <Button variant='contained'
          onClick={() => { navigate('/create-promo') }}

          sx={{
            mx: 1,
            width: '110px',
            height: '28px',
            fontSize: 12,
            borderRadius: "10px",
            textTransform: "capitalize",
            backgroundColor: colors.ufoGreen,
            color: colors.white,
            boxShadow: 'none',
            "&:hover": {
              backgroundColor: colors.ufoGreen,
            }
          }}>Add Promo </Button>
      </Box>

      <Box>
        <TableContainer>
          <Table stickyHeader sx={{ backgroundColor: colors.white }}>
            <StyledTableHead>
              <TableRow>
                {tableHeader.map((item) => (
                  <TableCell >{item}</TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {_DATA.currentData().map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell >{row.username}</TableCell>
                  <TableCell >{row.email}</TableCell>
                  <TableCell >
                    Value
                  </TableCell>
                  <TableCell >{row.status}</TableCell>
                </TableRow>
              )
              )}
            </StyledTableBody>
            <TableFooter>
              <TableRow>

              </TableRow>
            </TableFooter>
          </Table>
          <Box sx={{ py: 3 }}>
            <Pagination count={count}
              page={page}
              onChange={handleChange}
              shape="rounded"
              color="primary"
              sx={{ '.MuiPagination-ul': { justifyContent: 'center' } }}
            />
          </Box>
        </TableContainer>
      </Box>
    </Layout>
  )
}

export default PromotionsList